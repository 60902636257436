import React from "react";
import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils/types";

export const RepeatIcon = ({ className }: IconProps) => (
  <svg
    className={twMerge("h-5 w-5 text-primary-600", className)}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9997 5H6.66634C4.82539 5 3.33301 6.49238 3.33301 8.33333V9.16667M4.99967 15.8333H13.333C15.174 15.8333 16.6663 14.3409 16.6663 12.5V11.6667M14.1663 7.5L16.0771 5.58926C16.4025 5.26382 16.4025 4.73618 16.0771 4.41074L14.1663 2.5M5.83301 13.3333L3.92226 15.2441C3.59683 15.5695 3.59683 16.0972 3.92226 16.4226L5.83301 18.3333"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
