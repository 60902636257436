import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils/types";

export const FlagIcon = ({ className, ...props }: IconProps) => (
  <svg
    className={twMerge("h-3.5 w-3.5 text-orange-80", className)}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 11V5.44444M2.5 5.44444V1.29355C2.5 1.13143 2.63518 1 2.80193 1H4.31233C5.34719 1 6.33967 1.39968 7.07143 2.11111C7.80319 2.82254 8.79567 3.22222 9.83053 3.22222H10.2987C10.4099 3.22222 10.5 3.30984 10.5 3.41792V7.33705C10.5 7.51909 10.3482 7.66667 10.161 7.66667H8.68767C7.65281 7.66667 6.66033 7.26699 5.92857 6.55556C5.19681 5.84412 4.20433 5.44444 3.16947 5.44444H2.5Z"
      stroke="currentColor"
      strokeWidth="1.33"
      strokeLinecap="round"
    />
  </svg>
);
