import { useQuery } from "@tanstack/react-query";

import { getPaymentSettings } from "~/providers/api/paymentSettings";
import { useTeamMemberStore } from "~/providers/stores";

export const useGetPaymentSettings = () => {
  const { teamMember } = useTeamMemberStore();

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryFn: () => getPaymentSettings(),
    queryKey: ["getPaymentSettings"],
    enabled: !!teamMember,
  });
  return { data, isLoading, isFetching, refetch };
};
