import { twMerge } from "tailwind-merge";

import { FlagIcon } from "~/common/components";
import { Tag } from "~/common/components/Tag";
import { DESCRIPTIVE_TAG_VARIANTS, DescriptiveTagVariant } from "../utils";

interface DescriptiveTagProps {
  variant?: DescriptiveTagVariant;
}

export const DescriptiveTag = ({
  variant = "script_paused",
}: DescriptiveTagProps) => {
  return (
    <Tag
      className={twMerge(
        "h-fit whitespace-nowrap py-0.5",
        variant === DESCRIPTIVE_TAG_VARIANTS.script_paused &&
          "bg-orange-200 text-orange-80",
      )}
    >
      Scripts paused
      {variant === DESCRIPTIVE_TAG_VARIANTS.script_paused && <FlagIcon />}
    </Tag>
  );
};
