import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";
import DatePicker from "~/common/components/ui/DatePicker";
import TextArea from "~/common/components/ui/Textarea";
import { getTodayDate } from "~/common/utils/getTodayDate";
import { useDiscontinuePatientMedication } from "~/providers/hooks/queries/medications/useDiscontinuePatientMedication";
import { discontinueMedicationSchema } from "~/providers/utils";

interface DiscontinueMedicationModalProps {
  show: boolean;
  onClose: () => void;
  patientId: string;
  patientMedicationId: string;
}

export type DiscontinueFormValues = z.infer<typeof discontinueMedicationSchema>;
export const DiscontinueMedicationModal = ({
  show,
  onClose,
  patientId,
  patientMedicationId,
}: DiscontinueMedicationModalProps) => {
  const formDescription =
    "Please provide a justification for discontinuing this medication to confirm the action.";
  const today = getTodayDate();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<DiscontinueFormValues>({
    resolver: zodResolver(discontinueMedicationSchema),
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const { discontinuePatientMedicationMutation, isPending } =
    useDiscontinuePatientMedication(
      patientId,
      patientMedicationId,
      handleClose,
    );

  const onSubmit: SubmitHandler<DiscontinueFormValues> = (data) => {
    discontinuePatientMedicationMutation(data);
  };

  return (
    <Modal
      title="Discontinue medication"
      show={show}
      onClose={handleClose}
      description={formDescription}
    >
      <form
        className="flex w-full flex-col items-end gap-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DatePicker
          label="Discontinue as of"
          min={today}
          defaultValue={today}
          {...register("discontinued_at")}
          error={errors.discontinued_at?.message}
        />
        <TextArea
          label="Internal note"
          rows={4}
          {...register("discontinued_internal_note")}
          error={errors.discontinued_internal_note?.message}
        />
        <TextArea
          label="Message to patient"
          rows={4}
          {...register("discontinued_message_to_patient")}
          error={errors.discontinued_message_to_patient?.message}
        />
        <CancelSubmitButtons
          containerClassName="w-fit whitespace-nowrap"
          variant="right-outline-black"
          submitButtonLabel="Save changes"
          isSubmitting={isPending}
          handleCancel={handleClose}
        />
      </form>
    </Modal>
  );
};
