import { PatientMedication } from "~/common/types";
import { formatMedicationName } from "~/common/utils";
import { PauseMedicationFormValuesType } from "./scriptSchemas";

export const formatScriptDescription = (
  allMedications: PatientMedication[],
  medicationValues: PauseMedicationFormValuesType[],
) => {
  const medicationsNames = medicationValues.map(({ id }) => {
    const medication = allMedications.find(({ id: medId }) => medId === id)
      ?.medication;
    if (medication) return formatMedicationName(medication);
  });

  if (medicationsNames.length === 1) return medicationsNames[0];

  const lastMed = medicationsNames.pop();
  return `${medicationsNames.join(", ")} and ${lastMed}`;
};
