import { useQuery } from "@tanstack/react-query";

import { getCollidingOrders } from "~/providers/api/patientProfileService";
import { GetCollidingOrdersData } from "~/providers/utils/types/interactions";

export const useGetCollidingOrders = (params: GetCollidingOrdersData) => {
  const { data } = useQuery({
    queryFn: () => getCollidingOrders(params),
    queryKey: ["getCollidingOrders", params],
  });
  return { data };
};
