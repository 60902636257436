import { twMerge } from "tailwind-merge";

import { TableData } from "~/common/components/table";
import { TABLE_DATA_VARIANTS } from "~/common/constants";

export interface DetailsRowProps {
  detail: string | number;
  label: string;
  className?: string;
}

export const DetailsRow = ({ detail, label, className }: DetailsRowProps) => (
  <tr
    className={twMerge(
      "flex border-t border-neutral-700 py-3 leading-5 text-gray-70",
      className,
    )}
  >
    <TableData className="w-1/3" variant={TABLE_DATA_VARIANTS.outlined}>
      {label}
    </TableData>
    <TableData variant={TABLE_DATA_VARIANTS.outlined}>{detail}</TableData>
  </tr>
);
