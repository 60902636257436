import { PropsWithChildren } from "react";

interface MedicationMessageProps extends PropsWithChildren {
  title?: string;
}
export const MedicationMessage = ({
  title,
  children,
}: MedicationMessageProps) => (
  <div className="flex w-full flex-col gap-1">
    <p className="font-medium">{title}</p>
    <p className="text-sm font-light italic leading-4 text-gray-10">
      {children}
    </p>
  </div>
);
