import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { pausePatientMedicationScript } from "~/providers/api/patientMedicationsService";
import { PauseScriptFormValuesType } from "~/providers/utils";

export const usePauseMedicationScript = (
  patientId: string,
  onSuccess: () => void,
) => {
  const queryClient = useQueryClient();
  const { mutate: pauseMedicationScriptMutation, isPending } = useMutation({
    mutationFn: (data: PauseScriptFormValuesType) =>
      pausePatientMedicationScript(patientId, data),
    onSuccess: async () => {
      onSuccess();
      await queryClient.invalidateQueries({
        queryKey: ["getPatientMedications"],
      });
    },
    onError: () => toast.error("Something went wrong, try again later!"),
  });

  return { pauseMedicationScriptMutation, isPending };
};
