import { Note } from "~/common/components/ui";
import { useUpdateNoteMutation } from "~/providers/hooks/queries/patientInformation/useUpdateNoteMutation";
import { getPatientInfoCards } from "~/providers/utils/GetPatientInfoCards";
import { PatientData } from "~/providers/utils/types";
import InfoCard from "./InfoCard";

interface PatientDetailsProps {
  patient: PatientData;
}

export const PatientDetails = ({ patient }: PatientDetailsProps) => {
  const infoCards = getPatientInfoCards(patient);
  const { updateNoteMutation } = useUpdateNoteMutation(patient.id);

  const updateNote = async (note: string | null) => {
    await updateNoteMutation({ content: note });
  };

  return (
    <div className="flex w-full flex-col gap-4 p-8">
      <Note content={patient.note?.content} handleSubmitNote={updateNote} />
      {infoCards.map((card) => (
        <InfoCard {...card} key={card.title} />
      ))}
    </div>
  );
};
