import { useQuery } from "@tanstack/react-query";

import { CheckInType } from "~/common/utils/types/checkInTypes";
import { getCheckInsBasicList } from "~/providers/api/checkins";

export const useGetBasicCheckinsList = (
  checkInType: CheckInType,
  enabled: boolean,
  exclude?: string,
  patientId?: string,
) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getCheckInsBasicList(checkInType, exclude, patientId),
    queryKey: ["getCheckinsBasicList", checkInType, exclude, patientId],
    enabled,
  });
  return { data, isLoading };
};
