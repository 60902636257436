import { useQuery } from "@tanstack/react-query";

import { getPatientCheckinRelatedData } from "~/providers/api/patientProfileService";

export const useGetPatientCheckinRelatedData = (
  order_id: string,
  patientId?: string,
  enabled = false,
) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientCheckinRelatedData(patientId ?? "", order_id),
    queryKey: ["getPatientCheckinRelatedData", patientId, order_id],
    enabled: enabled && !!patientId,
  });
  return { data, isLoading };
};
