import { RECURRENT } from "~/common/constants";
import { checkInUtilities } from "~/common/utils/checkIn";
import { removeArrayDuplication } from "~/common/utils/removeArrayDuplication";
import { CollidingInteractions } from "../types/interactions";

const { formatCheckInCode } = checkInUtilities();

export const formatCollidingOrdersText = (
  collidingOrders: CollidingInteractions[],
) => {
  const uniqueFormattedCodes = removeArrayDuplication(
    collidingOrders.map(({ code }) =>
      formatCheckInCode(code).replace(RECURRENT, "").trim(),
    ),
  );

  const lastCode =
    uniqueFormattedCodes.length > 1 ? `and ${uniqueFormattedCodes.pop()}` : "";

  return `${uniqueFormattedCodes.join(", ")} ${lastCode}`;
};
