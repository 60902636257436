import { Comment } from "~/common/utils";
import { api } from "./axios";

export const getScriptComments = async (id?: string) => {
  const { data } = await api.get<Comment[]>(
    `/v1/team-members/scripts/${id}/comments`,
  );

  return data;
};

export const createScriptComment = async (text: string, id?: string) => {
  const { data } = await api.post<Comment>(
    `/v1/team-members/scripts/${id}/comments`,
    { text },
  );

  return data;
};
