import { useInfiniteQuery } from "@tanstack/react-query";

import { getPatientFeed } from "~/providers/api/patientProfileService";

export const useGetPatientFeeds = (patientId: string) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetched,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["getPatientFeeds", patientId],
    initialPageParam: 1,
    refetchOnWindowFocus: false,
    queryFn: ({ pageParam }) => getPatientFeed(patientId, pageParam),
    getNextPageParam: (lastPage) =>
      lastPage.page < lastPage.total_pages ? lastPage.page + 1 : undefined,
  });

  const loadMore = async () => {
    if (hasNextPage && !isFetchingNextPage) {
      await fetchNextPage();
    }
  };

  return { data, isFetched, loadMore, hasNextPage, isLoading };
};
