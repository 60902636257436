import { useState } from "react";

import { Button } from "~/common/components";
import { SignIcon } from "~/providers/components/icons";
import { CompleteAndSignFormModal } from "./CompleteAndSignFormModal";

interface AppointmentScheduledConsultationProps {
  patientId: string;
}

export const AppointmentScheduledConsultation = ({
  patientId,
}: AppointmentScheduledConsultationProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <Button
        className="w-auto font-normal"
        size="sm"
        onClick={() => setIsOpenModal(true)}
      >
        <SignIcon />
        Complete and sign
      </Button>
      <CompleteAndSignFormModal
        isOpenModal={isOpenModal}
        handleCloseModal={() => setIsOpenModal(false)}
        patientId={patientId}
      />
    </>
  );
};
