import { INTERACTION_CREATION_TYPES } from "~/common/utils/types/index";
import { Order } from "~/providers/utils/types/interactions";
import { BundleCheckinsData } from "./BundleCheckinsData";
import { ScheduleCheckinsData } from "./ScheduleCheckinData";
import { SpecialRequestCheckinsData } from "./SpecialRequestCheckinData";

interface OrderCheckinsDetailsProps {
  order: Order;
}
export const OrderCheckinsDetails = ({ order }: OrderCheckinsDetailsProps) => {
  const lookup = {
    [INTERACTION_CREATION_TYPES.special_request]: (
      <SpecialRequestCheckinsData order={order} />
    ),
    [INTERACTION_CREATION_TYPES.schedule]: (
      <ScheduleCheckinsData order={order} />
    ),
    [INTERACTION_CREATION_TYPES.bundle]: <BundleCheckinsData order={order} />,
    [INTERACTION_CREATION_TYPES.patient_created]: null,
  };

  return lookup[order.type];
};
