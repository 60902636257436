import { Link } from "react-router-dom";

import { ChevronIcon } from "~/common/components/icons";
import { DIRECTIONS } from "~/common/constants";
import { BASE_PATIENT, ROUTES } from "~/providers/router";

export const PatientHeader = ({ name }: { name: string }) => {
  const actualPath = window.location.pathname;
  const actualPathIncludesPatient = actualPath.includes(BASE_PATIENT);
  return (
    <div className="flex w-full flex-row items-center gap-3">
      <Link
        className="text-gray-10"
        to={
          actualPathIncludesPatient ? ROUTES.home : ROUTES.consultationRequests
        }
      >
        {actualPathIncludesPatient ? "Patients" : "Consultation Requests"}
      </Link>

      <ChevronIcon
        className="size-2.5 text-gray-10"
        direction={DIRECTIONS.right}
      />
      <p className="text-primary-600">{name}</p>
    </div>
  );
};
