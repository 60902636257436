import { twMerge } from "tailwind-merge";

interface OrderDescriptionLayoutProps {
  icon: () => React.JSX.Element;
  name: string;
  description: string;
  className?: string;
  bgColor: string;
}

export const OrderDescriptionLayout = ({
  icon,
  name,
  description,
  bgColor,
  className,
}: OrderDescriptionLayoutProps) => {
  return (
    <div
      className={twMerge(
        "flex w-full items-center gap-2 rounded-lg p-3",
        bgColor,
        className,
      )}
    >
      <div className="w-fit">{icon()}</div>
      <p className="text-sm text-gray-70">
        <span className="font-semibold">{name}</span> {description}
      </p>
    </div>
  );
};
