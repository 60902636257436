import { useMutation } from "@tanstack/react-query";

import { UpdateScriptData } from "~/common/types/scriptsTypes";
import { updateScriptRequest } from "~/providers/api/scriptsService";

export const useUpdateScriptRequest = (
  onError: () => void,
  onSuccess?: () => Promise<void> | void,
) => {
  const { mutate: updateScriptMutation, isPending } = useMutation({
    mutationFn: (data: UpdateScriptData) => updateScriptRequest(data),
    onSuccess,
    onError,
  });
  return { updateScriptMutation, isPending };
};
