import { Script, UpdateScriptData } from "~/common/types/scriptsTypes";
import { ProviderScriptDetails } from "../utils";
import { api, PaginatedServiceResponse } from "./axios";

export const getScriptsList = async (params?: URLSearchParams) => {
  const { data } = await api.get<PaginatedServiceResponse<Script[]>>(
    "/v1/team-members/scripts",
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
    },
  );
  return data;
};

export const getScriptDetails = async (id: string) => {
  const { data } = await api.get<ProviderScriptDetails>(
    `/v1/team-members/scripts/${id}`,
  );
  return data;
};

export const updateScriptRequest = async ({
  script_id,
  updatedData,
}: UpdateScriptData) => {
  const { data } = await api.put<Script>(
    `/v1/team-members/scripts/${script_id}`,
    updatedData,
  );

  return data;
};
