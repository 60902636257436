import React from "react";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { ArrowIcon } from "~/common/components/icons";
import { TextBubblesIcon } from "~/common/components/icons/TextBubblesIcon";
import { EmptyTableState, Table, TableHead } from "~/common/components/table";
import { TableHeader } from "~/common/components/table/TableHeader";
import { DIRECTIONS, ORDER, ORDER_BY } from "~/common/constants";
import { Patient } from "~/common/utils";
import { PATIENT_FILTER_PARAMS } from "../constants/patients";
import { PATIENTS_FILTERS } from "../utils";
import { LoadingPatientsTable } from "./LoadingPatientsTable";
import { PatientsTableRowData } from "./PatientsTableRowData";

interface PatientsTableProps {
  patients?: Patient[];
  isLoading?: boolean;
}

const PatientsTable = ({ patients, isLoading = false }: PatientsTableProps) => {
  const [params, setParams] = useSearchParams();

  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get(PATIENT_FILTER_PARAMS.filter);
  const isAllFilter = selectedTab === PATIENTS_FILTERS.all;

  const noPatients = !patients?.length;

  const handleNameSortClick = () => {
    if (params.get("order") === ORDER.descendant) {
      params.delete("order");
      params.delete("order_by");
      setParams(params);
    } else {
      const newParams = new URLSearchParams(params);
      newParams.set("order", ORDER.descendant);
      newParams.set("order_by", ORDER_BY.lastName);
      setParams(newParams);
    }
  };

  const handleDateSortClick = () => {
    if (params.get("order") === ORDER.descendant) {
      params.delete("order");
      params.set("order_by", ORDER_BY.completedDate);
      setParams(params);
    } else {
      params.set("order", ORDER.descendant);
      params.set("order_by", ORDER_BY.completedDate);
      setParams(params);
    }
  };

  return (
    <>
      <div
        className={twMerge(
          "w-full rounded-2xl border border-gray-200",
          noPatients && "h-table overflow-clip",
        )}
      >
        <Table>
          <TableHead className={twMerge("sticky top-0 overflow-hidden")}>
            <tr>
              <TableHeader scope="col" className="w-1/3 rounded-ss-2xl">
                <button
                  className="flex items-center gap-2"
                  onClick={handleNameSortClick}
                >
                  Name
                  <ArrowIcon
                    direction={
                      params.has("order") &&
                      params.get("order") === ORDER.descendant
                        ? DIRECTIONS.top
                        : DIRECTIONS.down
                    }
                  />
                </button>
              </TableHeader>
              <TableHeader className="w-1/3">
                Last patient interaction
              </TableHeader>
              <TableHeader className="rounded-se-2xl">
                <button
                  className="flex w-1/3 items-center gap-2"
                  onClick={handleDateSortClick}
                >
                  Date
                  <ArrowIcon
                    direction={
                      params.has("order") &&
                      params.get("order") === ORDER.descendant
                        ? DIRECTIONS.top
                        : DIRECTIONS.down
                    }
                  />
                </button>
              </TableHeader>
            </tr>
          </TableHead>
          {isLoading ? (
            <LoadingPatientsTable />
          ) : (
            <tbody className="max-h-[38rem] text-sm text-gray-70">
              {noPatients ? (
                <EmptyTableState
                  colSpan={3}
                  message={
                    isAllFilter ? "No patients found" : "No unreviewed patients"
                  }
                  icon={!isAllFilter ? <TextBubblesIcon /> : null}
                />
              ) : (
                patients?.map((patient) => (
                  <PatientsTableRowData key={patient.id} patient={patient} />
                ))
              )}
            </tbody>
          )}
        </Table>
      </div>
    </>
  );
};

export default PatientsTable;
