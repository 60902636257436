import { useMemo } from "react";

import { LoadingState } from "~/common/components";
import { EmptyState } from "~/common/components/EmptyState";
import { INTERACTION_CREATION_TYPES } from "~/common/utils/types/checkInTypes";
import { useGetPatientSchedules } from "~/providers/hooks/queries/useGetPatientSchedules";
import { useGetPendingPatientInteractions } from "~/providers/hooks/queries/useGetPendingPatientInteractions";
import { mergeAndSortScheduleAndInteractionsArrays } from "~/providers/utils/orders";
import { Order } from "~/providers/utils/types/interactions";
import { OrderCard } from "./OrderCard";

interface OrdersListProps {
  patientId: string;
  handleViewOrder: (order: Order) => void;
}
export const OrdersList = ({ patientId, handleViewOrder }: OrdersListProps) => {
  const { data: patientInteractions, isLoading: patientInteractionsLoading } =
    useGetPendingPatientInteractions(
      patientId,
      [
        INTERACTION_CREATION_TYPES.bundle,
        INTERACTION_CREATION_TYPES.special_request,
      ],
      true,
    );

  const { data: patientSchedules, isLoading: schedulesLoading } =
    useGetPatientSchedules(patientId);

  const mergedOrdersArrays = useMemo(
    () =>
      patientInteractions?.data && patientSchedules?.data
        ? mergeAndSortScheduleAndInteractionsArrays(
            patientInteractions.data,
            patientSchedules.data,
          )
        : [],
    [patientInteractions?.data, patientSchedules?.data],
  );

  const loadingOrders = patientInteractionsLoading || schedulesLoading;

  if (loadingOrders) return <LoadingState className="h-screen-calculated" />;

  if (!loadingOrders && mergedOrdersArrays.length === 0)
    return <EmptyState message="No orders added yet." />;

  return (
    <div className="flex w-full flex-col gap-3 py-6">
      {mergedOrdersArrays.map((order) => (
        <OrderCard
          type={order.type}
          code={order.checkin?.code}
          key={order.id}
          description={order.description}
          due_date={order.due_date}
          created_at={order.created_at}
          handleViewOrder={() => handleViewOrder(order)}
        />
      ))}
    </div>
  );
};
