import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { updateConsultationFee } from "~/providers/api/teamMemberPaymentAndCarePlans";

export const useUpdateConsultationFee = () => {
  const queryClient = useQueryClient();

  const { mutate: updateConsultationFeeMutation, isPending } = useMutation({
    mutationFn: updateConsultationFee,
    onError: () =>
      toast.error(
        "There was an issue updating your information. Please try again later.",
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPaymentSettings"],
      });
      toast.success("Default consultation fee updated.");
    },
  });

  return { updateConsultationFeeMutation, isPending };
};
