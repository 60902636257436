import React from "react";

export const StatusCheckDarkIcon = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.105469" width="32" height="32" rx="16" fill="#9371C4" />
      <path
        d="M7.29797 10.1109L7.29797 22.0999M11.649 12.5429L11.649 19.6678M16 6.12207L16 26.0889M20.351 10.7275V21.4834M24.702 12.5429V19.6678"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
