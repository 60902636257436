import {
  PatientMedicationDetail,
  PatientMedicationDetailsFormValues,
} from "~/common/types";
import { PRESCRIBE_ORDER_OPTIONS } from "~/providers/constants";
import { getMaxTakeValue, getTakeValue } from "./getMaxTakeValue";

export const generateDefaultPatientMedication = (
  values: PatientMedicationDetail,
): PatientMedicationDetailsFormValues => {
  const submitPrescriptionOrder = values?.submit_prescription_order
    ? PRESCRIBE_ORDER_OPTIONS.true.value
    : PRESCRIBE_ORDER_OPTIONS.false.value;

  const takeMax = getMaxTakeValue(values?.take);

  return {
    ...values,
    prescribed_quantity: values.prescribed_quantity.toString(),
    number_of_refills: values.number_of_refills.toString(),
    medication_strength_id: values.medication_strength.id,
    frequency_id: values?.frequency.id,
    take: getTakeValue(values.take).toString(),
    take_max: takeMax?.toString(),
    submit_prescription_order: submitPrescriptionOrder,
    range: !!takeMax,
  };
};
