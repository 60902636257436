import { useQuery } from "@tanstack/react-query";

import { getTeamMembersList } from "~/providers/api/authService";

export const useGetTeamMembersList = () => {
  const { data, isLoading } = useQuery({
    queryFn: getTeamMembersList,
    queryKey: ["getTeamMembersList"],
  });

  return { data, isLoading };
};
