import { useParams } from "react-router-dom";

import { PATIENT_STATUS } from "~/common/constants";
import { useGetPatient } from "~/providers/hooks";
import { useTeamMemberStore } from "~/providers/stores";
import { AppointmentScheduledConsultation } from "./postIntake";
import { ScreeningSubmittedConsultation } from "./postScreening";

interface PatientParams {
  patientId: string;
}

export const ConsultationRequestsPatient = () => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;
  const { data: patient } = useGetPatient(patientId);
  const { isStaff } = useTeamMemberStore();

  if (isStaff) return null;

  return (
    <div className="flex w-full gap-4 p-6 pb-0 md:px-14 md:pb-0 md:pt-8">
      <div className="flex-grow rounded-md bg-primary-50"></div>
      {patient?.status === PATIENT_STATUS.screening_submitted && (
        <ScreeningSubmittedConsultation patientId={patientId} />
      )}
      {patient?.status === PATIENT_STATUS.appointment_scheduled && (
        <AppointmentScheduledConsultation patientId={patientId} />
      )}
    </div>
  );
};
