import { useQuery } from "@tanstack/react-query";

import { getScriptDetails } from "~/providers/api/scriptsService";

export const useGetScriptDetails = (scriptId?: string) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getScriptDetails(scriptId ?? ""),
    queryKey: ["getProviderScript", scriptId],
    enabled: !!scriptId,
  });
  return { data, isLoading };
};
