import { useState } from "react";

import { Button } from "~/common/components";
import { ProceedToIntakeIcon, ReferIcon } from "~/providers/components/icons";
import {
  PROCEED_TO_INTAKE_ACTION,
  REFER_ACTION,
  ScreeningSubmittedConsultationsActions,
} from "~/providers/constants";
import { ProceedToIntakeFormModal } from "./ProceedToIntakeFormModal";
import { ReferPatientModal } from "./ReferPatientModal";

interface ScreeningSubmittedConsultationProps {
  patientId: string;
}

export const ScreeningSubmittedConsultation = ({
  patientId,
}: ScreeningSubmittedConsultationProps) => {
  const [patientNextAction, setPatientNextAction] =
    useState<ScreeningSubmittedConsultationsActions | null>();

  const toggleModal = (modalName?: ScreeningSubmittedConsultationsActions) => {
    setPatientNextAction(modalName);
  };

  return (
    <>
      <Button
        className="w-auto border border-gray-20 bg-white font-normal text-gray-700 hover:bg-neutral-20 focus:bg-neutral-20"
        size="sm"
        onClick={() => toggleModal(REFER_ACTION)}
      >
        <ReferIcon className="shrink-0 text-gray-20" />
        Refer
      </Button>
      <Button
        className="w-auto font-normal"
        size="sm"
        onClick={() => toggleModal(PROCEED_TO_INTAKE_ACTION)}
      >
        <ProceedToIntakeIcon />
        Proceed to intake
      </Button>
      <ReferPatientModal
        handleCloseModal={toggleModal}
        patientId={patientId}
        isOpenModal={patientNextAction === REFER_ACTION}
      />

      <ProceedToIntakeFormModal
        isOpenModal={patientNextAction === PROCEED_TO_INTAKE_ACTION}
        handleCloseModal={toggleModal}
        patientId={patientId}
      />
    </>
  );
};
