import { useQuery } from "@tanstack/react-query";

import { getCheckInWithAnswers } from "~/providers/api/checkins";

interface UseGetCompletedCheckInProps {
  patientId: string;
  checkInId?: string;
}
export const useGetCompletedCheckIn = ({
  patientId,
  checkInId,
}: UseGetCompletedCheckInProps) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getCheckInWithAnswers(patientId, checkInId ?? ""),
    queryKey: ["getCheckins", patientId, checkInId],
    enabled: !!checkInId,
  });
  return { data, isLoading };
};
