import React from "react";
import { useNavigate } from "react-router-dom";

import { InternalError } from "~/common/assets";
import { DescriptiveErrorLayout } from "~/common/components/layouts/DescriptiveErrorLayout";
import { ROUTES } from "../router";

export const InternalErrorScreen = () => {
  const navigate = useNavigate();
  return (
    <DescriptiveErrorLayout
      errorType="500"
      title="Internal Server Error"
      description="Sorry, there were some technical issues while processing your request."
      handleBackToHome={() => navigate(ROUTES.home)}
    >
      <img src={InternalError} alt="error 500" />
    </DescriptiveErrorLayout>
  );
};
