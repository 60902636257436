import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { TableData } from "~/common/components";
import { formatDateWithHour, Patient } from "~/common/utils";
import { ROUTES } from "../router";
import { NotificationPoint } from "./icons";

interface PatientDataProps {
  patient: Patient;
}

export const PatientsTableRowData = ({ patient }: PatientDataProps) => {
  const navigate = useNavigate();

  const handleClick = () =>
    navigate(
      generatePath(ROUTES.activityFeed, {
        patientId: patient.id,
      }),
    );

  return (
    <tr
      onClick={handleClick}
      className={twMerge(
        "transition hover:cursor-pointer hover:bg-gray-50",
        patient.last_feed && !patient.last_feed && "font-semibold",
      )}
    >
      <TableData>
        <div className="flex items-center gap-4">
          <NotificationPoint
            className={
              patient.last_feed && !patient.last_feed?.reviewed_at
                ? "text-red-500"
                : "text-neutral-200"
            }
          />
          {patient.last_name}, {patient.name}
        </div>
      </TableData>
      <TableData>
        {patient.last_feed ? patient.last_feed.description : "-"}
      </TableData>
      <TableData>
        {patient.last_feed
          ? formatDateWithHour(patient.last_feed.created_at)
          : "-"}
      </TableData>
    </tr>
  );
};
