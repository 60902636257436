import { useState } from "react";

import { useDeleteOrderAndRelated } from "~/providers/hooks/queries/useDeleteOrderAndRelated";
import { useDeleteSchedule } from "~/providers/hooks/queries/useDeleteSchedule";
import { AddOrderModal } from "~/providers/modals/AddOrderModal";
import { Order } from "~/providers/utils/types/interactions";
import { OrderDetailsDrawer, OrdersLayout } from "./orderPanel/index";
import { OrdersList } from "./orderPanel/OrdersList";

interface OrderPanelProps {
  patientId: string;
}

export const OrderPanel = ({ patientId }: OrderPanelProps) => {
  const [addOrderModal, setAddOrderModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>();

  const handleCloseDrawer = () => setSelectedOrder(undefined);

  const {
    deleteOrderAndRelatedMutation,
    isPending: loadingDeleteOrderAndRelated,
  } = useDeleteOrderAndRelated({
    patientId,
    orderId: selectedOrder?.id,
    handleCloseDrawer,
  });

  const { deleteScheduleMutation, isPending: loadingDeleteSchedule } =
    useDeleteSchedule({
      patientId,
      scheduleId: selectedOrder?.id,
      handleCloseDrawer,
    });

  const handleDelete = () => {
    if (selectedOrder?.type === "schedule") {
      deleteScheduleMutation();
    } else {
      deleteOrderAndRelatedMutation();
    }
  };

  const loadingDelete = loadingDeleteOrderAndRelated || loadingDeleteSchedule;

  return (
    <OrdersLayout handleAddOrder={() => setAddOrderModal(true)}>
      <OrdersList
        patientId={patientId}
        handleViewOrder={(order) => setSelectedOrder(order)}
      />
      <OrderDetailsDrawer
        order={selectedOrder}
        handleClose={handleCloseDrawer}
        handleDelete={handleDelete}
        loadingDelete={loadingDelete}
      />
      <AddOrderModal
        patientId={patientId}
        show={addOrderModal}
        onClose={() => setAddOrderModal(false)}
      />
    </OrdersLayout>
  );
};
