import { matchPath, NavigateFunction } from "react-router-dom";

import { ROUTES } from "../router";

interface ProviderSettingsMenuOptions {
  label: string;
  onClick: () => void;
  current?: boolean;
  enabled: boolean;
}

export const getSettingsLayoutMenuOptions = (navigate: NavigateFunction) => {
  const actualPath = window.location.pathname;

  const menuOptions: ProviderSettingsMenuOptions[] = [
    {
      label: "Account",
      onClick: () => {
        navigate(ROUTES.account);
      },
      current: !!matchPath(ROUTES.account, actualPath),
      enabled: true,
    },
    {
      label: "Payment & Care Plans",
      onClick: () => {
        navigate(ROUTES.paymentAndCarePlans);
      },
      current: !!matchPath(ROUTES.paymentAndCarePlans, actualPath),
      enabled: true,
    },
    {
      label: "Scheduling",
      onClick: () => {
        navigate(ROUTES.scheduling);
      },
      current: !!matchPath(ROUTES.scheduling, actualPath),
      enabled: true,
    },
  ];

  return { menuOptions };
};
