import { WarningMessage } from "~/common/components/WarningMessage";
import {
  BasicCheckIn,
  CHECK_IN_TYPES,
  PATIENT_INTERACTION_TYPES,
} from "~/common/utils/types/checkInTypes";
import { useGetCollidingOrders } from "~/providers/hooks/queries/useGetCollidingOrders";
import { formatCollidingOrdersText } from "~/providers/utils/orders/formatCollidingOrdersText";

interface RenderWarningProps {
  patientId: string;
  selectedOrder: BasicCheckIn;
}

export const RenderWarning = ({
  patientId,
  selectedOrder,
}: RenderWarningProps) => {
  const collidingType =
    selectedOrder.type === CHECK_IN_TYPES.bundles
      ? PATIENT_INTERACTION_TYPES.bundle
      : PATIENT_INTERACTION_TYPES.checkin;

  const { data: collidingOrdersData } = useGetCollidingOrders({
    patientId,
    orderId: selectedOrder.id,
    orderType: collidingType,
  });

  const collidingOrders = collidingOrdersData?.data;

  if (!collidingOrders || collidingOrders.length === 0) return null;

  return (
    <WarningMessage>
      <span className="font-semibold">
        One or more {formatCollidingOrdersText(collidingOrders)} orders already
        exists for this patient.{" "}
      </span>
      The current order will be created alongside existing orders. Unwanted
      orders can be deleted from the{" patient's "}
      <span className="font-semibold">Care Plan</span> at any time.
    </WarningMessage>
  );
};
