import { URLSearchParams } from "url";
import { useQuery } from "@tanstack/react-query";

import { DEFAULT_PAGE_SIZE } from "~/common/constants";
import { getPatients } from "~/providers/api/patients";
import { PATIENT_FILTER_PARAMS } from "~/providers/constants";

export const useGetPatients = (params: URLSearchParams, enabled?: boolean) => {
  !params.has(PATIENT_FILTER_PARAMS.pageSize) &&
    params.append(PATIENT_FILTER_PARAMS.pageSize, DEFAULT_PAGE_SIZE);
  const { data, isLoading, isFetched } = useQuery({
    queryFn: () => getPatients(params),
    queryKey: ["getPatients", params.toString(), params],
    enabled,
  });
  return { data, isLoading, isFetched };
};
