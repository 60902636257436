import { twMerge } from "tailwind-merge";

import { Typography } from "~/common/components";
import { USER_TYPES } from "~/common/constants";
import { BasicUserData, formatDateWithHour } from "~/common/utils";
import { CommentTitleType } from "~/patients/utils";

interface CommentCardProps {
  title?: CommentTitleType;
  text: string;
  user?: BasicUserData;
  created_at: string;
}

export const CommentCard = ({
  title,
  created_at,
  user,
  text,
}: CommentCardProps) => {
  const isTeamMember =
    user?.type === USER_TYPES.provider || user?.type === USER_TYPES.staff;

  return (
    <div
      className={twMerge(
        "flex flex-col gap-2 rounded-md border border-neutral-700 bg-white p-3",
        isTeamMember && "border-primary-300 bg-primary-100",
      )}
    >
      {user?.type === USER_TYPES.patient && (
        <div className="flex justify-between text-sm text-gray-30">
          <p>{title}</p>
          <p>{formatDateWithHour(created_at)}</p>
        </div>
      )}

      <div className="flex flex-col gap-2 text-gray-70">
        <div className="flex justify-between">
          {user && (
            <h2 className="font-medium ">{`${user.name} ${user.last_name}`}</h2>
          )}
          {isTeamMember && (
            <p className="flex justify-between text-sm text-gray-30">
              {formatDateWithHour(created_at)}
            </p>
          )}
        </div>
        <Typography className="break-all">{text}</Typography>
      </div>
    </div>
  );
};
