import { generatePath, matchPath, NavigateFunction } from "react-router-dom";

import { PATIENT_STATUS } from "~/common/constants";
import { PatientStatus } from "~/common/utils";
import { ROUTES } from "~/providers/router";

export interface MenuOptionData {
  label: string;
  onClick: () => void;
  value?: string;
  notificationAmount?: number;
  current?: boolean;
  enabled?: boolean;
}

export const getPatientProfileMenuOptions = (
  navigate: NavigateFunction,
  patientId: string,
  patientStatus: PatientStatus,
): MenuOptionData[] => {
  const actualPath = window.location.pathname;
  const isPatientActive = patientStatus === PATIENT_STATUS.ongoing_care;

  return [
    {
      label: "Activity feed",
      current: !!matchPath(ROUTES.activityFeed, actualPath),
      onClick: () =>
        navigate(
          generatePath(ROUTES.activityFeed, {
            patientId: patientId,
          }),
        ),
      enabled: isPatientActive,
    },
    {
      label: "Medications",
      current: !!matchPath(ROUTES.medication, actualPath),
      onClick: () =>
        navigate(
          generatePath(ROUTES.medication, {
            patientId: patientId,
          }),
        ),
      enabled: isPatientActive,
    },
    {
      label: "Orders",
      current: !!matchPath(ROUTES.orders, actualPath),
      onClick: () =>
        navigate(
          generatePath(ROUTES.orders, {
            patientId: patientId,
          }),
        ),
      enabled: isPatientActive,
    },
    {
      label: "Consultation",
      current: !!matchPath(ROUTES.consultationRequestsPatient, actualPath),
      onClick: () =>
        navigate(
          generatePath(ROUTES.consultationRequestsPatient, { patientId }),
        ),
      enabled: !isPatientActive,
    },
    {
      label: "Patient Info",
      current: !!matchPath(ROUTES.info, actualPath),
      onClick: () =>
        navigate(
          generatePath(ROUTES.info, {
            patientId: patientId,
          }),
        ),
      enabled: true,
    },
  ];
};
