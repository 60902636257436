export const getMaxTakeValue = (takeString: string) => {
  const parts = takeString.split(" to ");
  if (parts.length > 1) return parseFloat(parts[1]);
  return parseFloat(takeString);
};

export const getTakeValue = (takeString: string) => {
  const parts = takeString.split(" to ");
  return parseFloat(parts[0]);
};
