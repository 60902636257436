import { useMemo } from "react";

interface PaginationProps {
  totalItems: number;
  currentPage: number;
  totalPages: number;
}

const usePagination = ({
  totalItems,
  currentPage,
  totalPages,
}: PaginationProps) => {
  const range = (start: number, end: number) => {
    const length = end - start + 1;

    return Array.from({ length }, (_, idx) => idx + start);
  };

  const paginationRange = useMemo(() => {
    if (totalPages < 5) {
      return range(1, totalPages);
    }

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;
    const shouldShowLeftDots = currentPage - firstPageIndex >= 2;
    const shouldShowRightDots = lastPageIndex - currentPage > 1;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftRange = range(
        1,
        currentPage === 1 ? currentPage + 2 : currentPage + 1,
      );
      return [...leftRange, null, totalPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightRange = range(
        currentPage === lastPageIndex ? currentPage - 2 : currentPage - 1,
        lastPageIndex,
      );
      return [firstPageIndex, null, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(currentPage - 1, currentPage + 1);
      return [firstPageIndex, null, ...middleRange, null, lastPageIndex];
    }
  }, [totalItems, 1, currentPage]);

  return paginationRange;
};

export default usePagination;
