import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { createMedication } from "~/providers/api/patientMedicationsService";

interface UseCreateMedicationProps {
  onSuccess: () => void;
}

export const useCreateMedication = ({
  onSuccess,
}: UseCreateMedicationProps) => {
  const queryClient = useQueryClient();
  const { mutate: createMedicationMutation, isPending } = useMutation({
    mutationFn: createMedication,
    onError: () =>
      toast.error(
        "We had trouble adding the patient's medication. Try again later!",
      ),
    onSuccess: async () => {
      onSuccess();
      await queryClient.invalidateQueries({
        queryKey: ["getPatientMedications"],
      });
    },
  });

  return { createMedicationMutation, isPending };
};
