export const HealthFactorsIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#FAE6EE" />
    <path
      d="M10.8638 18.7501C12.5704 20.4289 14.6744 22.3064 15.5645 23.0922C15.7142 23.2243 15.7891 23.2904 15.8764 23.3164C15.9526 23.3391 16.0361 23.3391 16.1123 23.3164C16.1996 23.2904 16.2745 23.2243 16.4242 23.0922C17.3143 22.3064 19.4183 20.4289 21.1249 18.7501C21.9847 17.9043 22.7437 17.109 23.1957 16.5291C23.5451 16.081 23.8074 15.5921 23.983 15.0834H17.1999C17.0263 15.0834 16.8676 15.1815 16.79 15.3368L15.5253 17.8662C15.3508 18.2152 14.8478 18.2004 14.6941 17.8418L12.6666 13.1111C12.5233 12.7766 12.0659 12.7346 11.864 13.0374L10.6361 14.8793C10.5511 15.0068 10.408 15.0834 10.2547 15.0834H8.00888C7.80584 14.4881 7.72287 13.8661 7.75773 13.2501C7.83121 11.9518 8.42811 10.6806 9.52762 9.74409C11.5943 7.98387 14.3449 8.50095 15.9944 10.4232C17.6438 8.50095 20.3589 8.00239 22.4611 9.74409C23.04 10.2237 23.4789 10.7959 23.777 11.4167"
      stroke="#EC5D88"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
