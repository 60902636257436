import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

import { DIRECTIONS } from "~/common/constants";
import { Option } from "~/common/utils";
import CheckBox from "~/patients/components/ui/CheckBox";
import { ChevronIcon } from "../../icons";

interface MultiSelectListBoxProps {
  options: Option[];
  disabled?: boolean;
  clearAllLabel?: string;
  placeholder?: string;
  description?: string;
  defaultValues: string[];
  containerClassName?: string;
  onChange?: (value: string[]) => void;
  controllerOnChange?: (value: string[]) => void;
  handleClearAll?: () => void;
}

export const MultiSelectListBox = ({
  options,
  placeholder,
  description,
  clearAllLabel = "Clear all",
  containerClassName,
  onChange,
  controllerOnChange,
  handleClearAll,
  defaultValues,
  disabled,
}: MultiSelectListBoxProps) => (
  <Listbox
    disabled={disabled}
    value={defaultValues}
    multiple
    onChange={(val) => {
      onChange?.(val);
      controllerOnChange?.(val);
    }}
  >
    {({ open }) => (
      <div className={twMerge("relative", containerClassName)}>
        <Listbox.Button
          aria-disabled={disabled}
          className={twMerge(
            "relative flex w-full min-w-44 items-center justify-between gap-3 rounded-lg border px-3 py-2.5 text-left sm:text-sm",
            "border-gray-70 bg-neutral-25 text-gray-70",
            "disabled:border-gray-20 disabled:bg-neutral-5 disabled:text-gray-60",
            "hover:bg-neutral-10",
          )}
        >
          <div
            className={twMerge(
              "flex items-center gap-1.5",
              disabled && "text-gray-600",
            )}
          >
            {placeholder}
            {Boolean(defaultValues.length) && (
              <div className="flex size-5 items-center justify-center rounded-full bg-primary-500 text-xs text-white">
                {defaultValues.length}
              </div>
            )}
          </div>
          <ChevronIcon
            direction={open ? DIRECTIONS.top : DIRECTIONS.down}
            className={twMerge(
              "h-3 transition-all duration-300",
              disabled && "text-gray-600",
            )}
          />
        </Listbox.Button>
        {!disabled && (
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="scrollbar-hide border-gray-25 absolute z-50 mt-1 flex max-h-60 w-full flex-col gap-3 overflow-auto rounded-md border border-gray-200 bg-white p-3 text-left text-base shadow-lg sm:text-sm">
              {handleClearAll && (
                <button
                  type="button"
                  onClick={() => {
                    onChange?.([]);
                    controllerOnChange?.([]);
                  }}
                  className="w-fit select-none font-medium text-gray-30"
                >
                  {clearAllLabel}
                </button>
              )}
              <div className="flex flex-col gap-1">
                <span className="text-xs font-medium text-gray-20">
                  {description}
                </span>
                <div className="flex w-full flex-col gap-3">
                  {options.map(({ value, label }) => (
                    <Listbox.Option
                      key={value}
                      className="relative select-none first-letter:uppercase"
                      value={value}
                    >
                      <div className="flex flex-row items-center gap-3">
                        <CheckBox
                          readOnly
                          id={value}
                          checked={defaultValues?.includes(value)}
                        >
                          {label}
                        </CheckBox>
                      </div>
                    </Listbox.Option>
                  ))}
                </div>
              </div>
            </Listbox.Options>
          </Transition>
        )}
      </div>
    )}
  </Listbox>
);
