import { PropsWithChildren } from "react";

import { CheckIcon } from "~/common/components";

export const CheckDetails = ({ children }: PropsWithChildren) => (
  <div className="flex items-start gap-1.5 text-gray-70">
    <CheckIcon strokeWidth="1.5" />
    {children}
  </div>
);
