import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { CancelSubmitButtons } from "~/common/components";
import Select from "~/common/components/ui/select/Select";
import TextArea from "~/common/components/ui/Textarea";
import { OWNER_OPTIONS } from "~/providers/constants";
import {
  useGetTeamMembersList,
  useUpdateScriptRequest,
} from "~/providers/hooks";
import {
  editScriptSchema,
  EditScriptSchemaFormValuesType,
  generateTeamMemberOptions,
  ProviderScriptDetails,
} from "~/providers/utils";
import { getScriptStatusOptions } from "~/providers/utils/medicationScripts/getScriptStatusOptions";

interface ScriptDetailsProps {
  id?: string;
  scriptRequest?: ProviderScriptDetails;
  handleCancel: () => void;
}

export const ScriptDetails = ({
  id,
  scriptRequest,
  handleCancel,
}: ScriptDetailsProps) => {
  const toggleSuccessMessage = () =>
    toast.success("Changes successfully saved");

  const handleOnError = () =>
    toast.error("We shoot trouble updating the script.");
  const handleOnSuccess = () => void toggleSuccessMessage();

  const { updateScriptMutation, isPending } = useUpdateScriptRequest(
    handleOnError,
    handleOnSuccess,
  );
  const { data: teamMembers } = useGetTeamMembersList();

  const ownersOptions = generateTeamMemberOptions(teamMembers);
  const {
    formState: { errors, isDirty },
    handleSubmit,
    control,
    getValues,
    setValue,
    register,
  } = useForm<EditScriptSchemaFormValuesType>({
    resolver: zodResolver(editScriptSchema),
    ...(scriptRequest
      ? {
          values: {
            owner_id: scriptRequest?.owner?.id ?? OWNER_OPTIONS.unassigned,
            resolution: scriptRequest.resolution ?? "",
            status: scriptRequest.status,
          },
        }
      : {}),
  });

  const isSubmitDisabled = isPending || !isDirty;

  const onSubmit = (formData: EditScriptSchemaFormValuesType) =>
    updateScriptMutation({
      script_id: id ?? "",
      updatedData: {
        ...formData,
        owner_id:
          formData.owner_id !== OWNER_OPTIONS.unassigned
            ? formData.owner_id
            : null,
        resolution: formData.resolution ? formData.resolution : null,
      },
    });

  const handleStatusChange = (value: string | number) => {
    if (value != getValues("status")) setValue("resolution", undefined);
  };

  return (
    <form
      className="h-3/7 flex flex-col justify-between gap-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex h-full flex-col">
        <div className="flex flex-col gap-4 px-4 pt-4">
          <div className="flex gap-4">
            <Select
              id="owner_id"
              label="Owner"
              options={ownersOptions}
              control={control}
              error={errors.owner_id?.message}
            />
            <Select
              id="status"
              control={control}
              label="Status"
              options={
                scriptRequest
                  ? getScriptStatusOptions(scriptRequest.status)
                  : []
              }
              onChange={handleStatusChange}
              error={errors.status?.message}
            />
          </div>

          <TextArea
            label="Resolution"
            error={errors.resolution?.message}
            {...register("resolution")}
            className="h-24"
          />
        </div>
        <div className="flex w-full flex-col items-end gap-2 p-4">
          <CancelSubmitButtons
            handleCancel={handleCancel}
            submitButtonLabel="Save"
            containerClassName="w-7/12 h-11"
            isSubmitting={isPending}
            disableSubmit={isSubmitDisabled}
            submitClassName="disabled:bg-neutral-700 disabled:text-gray-35"
          />
        </div>
      </div>
    </form>
  );
};
