import { FieldErrors, UseFormRegister, UseFormWatch } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { ErrorText, TransitionEffect } from "~/common/components";
import TextArea from "~/common/components/ui/Textarea";
import CheckBox from "~/patients/components/ui/CheckBox";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import {
  PRESCRIBE_ORDER_OPTIONS,
  PRESCRIBE_ORDER_VALUES,
} from "~/providers/constants/medications";
import { UpsertMedicationFormValues } from "../UpsertMedicationModal";

interface SubmitPrescriptionInputsProps {
  register: UseFormRegister<UpsertMedicationFormValues>;
  watch: UseFormWatch<UpsertMedicationFormValues>;
  errors: FieldErrors<UpsertMedicationFormValues>;
  enableMedicationChangeOrders: boolean;
}

export const SubmitPrescriptionInputs = ({
  register,
  watch,
  errors,
  enableMedicationChangeOrders,
}: SubmitPrescriptionInputsProps) => (
  <div className="flex flex-col gap-3 border-t border-neutral-700 pt-3">
    <div className="flex flex-col gap-2">
      <CheckBox
        id="medicationChange"
        className={twMerge(
          "h-4 w-4 border-neutral-700",
          !enableMedicationChangeOrders && "cursor-default",
        )}
        labelClassName={twMerge(
          "text-sm",
          !enableMedicationChangeOrders && "text-gray-600",
        )}
        {...register(
          "patient_medications_detail.start_medication_change_order",
        )}
        disabled={!enableMedicationChangeOrders}
      >
        {enableMedicationChangeOrders
          ? "Start ‘Medication change’ orders"
          : "Bundled 'Medication change' orders unavailable."}
      </CheckBox>
    </div>
    <div className="flex w-full flex-col gap-2 border-t border-neutral-700 pt-3">
      <div className="flex flex-col gap-1">
        <div className="flex w-full flex-col gap-2">
          {PRESCRIBE_ORDER_VALUES.map((option) => (
            <RadioButtonOption
              key={option.label}
              value={option.value}
              label={option.label}
              variant="simple"
              {...register(
                "patient_medications_detail.submit_prescription_order",
              )}
            />
          ))}
        </div>
        {errors.patient_medications_detail?.submit_prescription_order
          ?.message && (
          <ErrorText>
            {
              errors.patient_medications_detail?.submit_prescription_order
                ?.message
            }
          </ErrorText>
        )}
      </div>
      <TransitionEffect
        show={
          watch("patient_medications_detail.submit_prescription_order") ===
          PRESCRIBE_ORDER_OPTIONS.false.value
        }
      >
        <TextArea
          placeholder="Add justification for this option"
          {...register(
            "patient_medications_detail.submit_prescription_order_reason",
          )}
          rows={3}
          containerClassName="gap-0"
          error={
            errors.patient_medications_detail?.submit_prescription_order_reason
              ?.message
          }
        />
      </TransitionEffect>
    </div>
  </div>
);
