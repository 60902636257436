import { EmptyState, LoadingState } from "~/common/components";
import { PATIENT_MEDICATION_STATUS } from "~/common/constants/medications";
import { useGetPatientMedications } from "~/providers/hooks/queries/medications/useGetPatientMedications";
import { MedicationCard } from "./card/MedicationCard";

interface DiscontinuedMedicationsProps {
  patientId: string;
}

export const DiscontinuedMedications = ({
  patientId,
}: DiscontinuedMedicationsProps) => {
  const { data: discontinuedPatientMedications, isLoading } =
    useGetPatientMedications(PATIENT_MEDICATION_STATUS.discontinued, patientId);

  if (isLoading) return <LoadingState />;

  if (
    discontinuedPatientMedications &&
    !discontinuedPatientMedications?.data.length
  ) {
    return (
      <EmptyState
        message="There are no past medications yet."
        className="h-80 border-t"
      />
    );
  }
  return discontinuedPatientMedications?.data.map((med) => (
    <MedicationCard
      {...med}
      status={PATIENT_MEDICATION_STATUS.discontinued}
      key={med.id}
    />
  ));
};
