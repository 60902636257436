import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { ErrorResponse } from "~/providers/api";
import { updatePaymentSettings } from "~/providers/api/teamMemberPaymentAndCarePlans";

export const useUpdatePaymentSettings = () => {
  const queryClient = useQueryClient();
  const [validationErrorDetail, setValidationErrorDetail] = useState("");

  const { mutate: updatePaymentSettingsMutation, isPending } = useMutation({
    mutationFn: updatePaymentSettings,
    onError: (error: ErrorResponse) => {
      setValidationErrorDetail(error.response?.data?.detail ?? "");
      toast.error(
        "There was an issue updating your information. Please try again later.",
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPaymentSettings"],
      });
      setValidationErrorDetail("");
      toast.success("Payment settings updated.");
    },
  });

  return { updatePaymentSettingsMutation, isPending, validationErrorDetail };
};
