import { twMerge } from "tailwind-merge";

import { PATIENTS_FILTERS, PatientsFilters } from "../utils/types";

interface FilterPatientsButtonProps {
  filter: PatientsFilters;
  disabled?: boolean;
  onFilterChanged: (value: PatientsFilters) => void;
}
const FilterPatientsButton = ({
  filter,
  disabled = false,
  onFilterChanged,
}: FilterPatientsButtonProps) => {
  return (
    <div className={twMerge(disabled && "opacity-50")}>
      <button
        disabled={disabled}
        className={twMerge(
          "rounded-l-lg border border-r-0 border-gray-200 p-3  text-gray-700",
          filter === PATIENTS_FILTERS.all &&
            "border-r border-blue-600 bg-blue-100 font-medium text-blue-600",
        )}
        onClick={() => onFilterChanged(PATIENTS_FILTERS.all)}
      >
        All
      </button>
      <button
        disabled={disabled}
        className={twMerge(
          "rounded-r-lg border border-gray-200 p-3 text-gray-700",
          filter === PATIENTS_FILTERS.unreviewed &&
            "border-l border-blue-600 bg-blue-100 font-medium text-blue-600",
        )}
        onClick={() => onFilterChanged(PATIENTS_FILTERS.unreviewed)}
      >
        Unreviewed
      </button>
    </div>
  );
};

export default FilterPatientsButton;
