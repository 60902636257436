import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";
import { PATIENT_STATUS } from "~/common/constants";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import { ADMIT_TO_PRACTICE, REFER_PATIENT } from "~/providers/constants";
import { useChangePatientStatus } from "~/providers/hooks/queries/useChangePatientStatus";
import {
  completeAndSignSchema,
  CompleteAndSignType,
} from "~/providers/utils/consultationRequests/schemas";

interface CompleteAndSignFormModalProps {
  handleCloseModal: () => void;
  isOpenModal: boolean;
  patientId: string;
}

export const CompleteAndSignFormModal = ({
  handleCloseModal,
  isOpenModal,
  patientId,
}: CompleteAndSignFormModalProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CompleteAndSignType>({
    resolver: zodResolver(completeAndSignSchema),
  });

  const admitToPracticeValue = watch("admit_to_practice");

  const onSuccess = () => {
    handleCloseModal();
    toast.success(
      `Patient ${
        admitToPracticeValue === ADMIT_TO_PRACTICE.do_not_admit
          ? "not admitted"
          : "admitted"
      } - Consultation completed successfully!`,
    );
  };
  const { changePatientStatusMutation } = useChangePatientStatus(onSuccess);

  const onSubmit: SubmitHandler<CompleteAndSignType> = (data) => {
    changePatientStatusMutation({
      patientId,
      status:
        data.admit_to_practice === ADMIT_TO_PRACTICE.do_not_admit
          ? PATIENT_STATUS.consultation_completed
          : PATIENT_STATUS.ongoing_care,
    });
  };

  return (
    <Modal
      className="h-fit w-96"
      titleClassName="text-2xl font-medium w-2/3 text-gray-70"
      title="Complete and sign"
      show={isOpenModal}
      onClose={handleCloseModal}
    >
      <div>
        <form
          className="flex h-full w-full flex-col gap-4 pb-2 text-gray-70"
          onSubmit={handleSubmit(onSubmit)}
        >
          <span className="text-lg font-semibold">Admit to practice</span>
          <div className="flex gap-6">
            <RadioButtonOption
              label="Do not admit"
              variant="simple"
              containerClassName="w-auto"
              {...register("admit_to_practice")}
              value={ADMIT_TO_PRACTICE.do_not_admit}
            />
            <RadioButtonOption
              label="Admit"
              variant="simple"
              containerClassName="w-auto"
              {...register("admit_to_practice")}
              value={ADMIT_TO_PRACTICE.admit}
            />
          </div>
          {Boolean(errors.admit_to_practice) && (
            <span className="text-xs text-error">Required</span>
          )}
          <span className="text-lg font-semibold">Refer patient</span>
          <div className="flex gap-6">
            <RadioButtonOption
              label="No referral needed"
              containerClassName="w-auto"
              variant="simple"
              {...register("refer_patient")}
              value={REFER_PATIENT.no_referral_needed}
            />
            <RadioButtonOption
              label="Referral provided"
              variant="simple"
              containerClassName="w-auto"
              {...register("refer_patient")}
              value={REFER_PATIENT.referral_provided}
            />
          </div>
          {Boolean(errors.refer_patient) && (
            <span className="text-xs text-error">Required</span>
          )}
          <div className="flex flex-col gap-3 self-end">
            <CancelSubmitButtons
              cancelClassName="text-sm w-32 text-gray-70 border-gray-70"
              submitClassName="w-32"
              handleCancel={handleCloseModal}
              cancelButtonLabel="Cancel"
              submitButtonLabel="Confirm"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
