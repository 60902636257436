import { useState } from "react";

import { DisplayNote } from "./DisplayNote";
import { EditNote } from "./EditNote";

interface NoteProps {
  content?: string;
  handleSubmitNote?: (note: string | null) => Promise<void>;
}

export const Note = ({ content, handleSubmitNote }: NoteProps) => {
  const [editNote, setEditNote] = useState(false);
  const toggleEditNote = () => setEditNote((prev) => !prev);

  const handleSubmit = async (note: string | null) => {
    if (!handleSubmitNote) return;
    try {
      await handleSubmitNote(note);
      toggleEditNote();
    } catch {
      return;
    }
  };

  return editNote ? (
    <EditNote
      handleClose={toggleEditNote}
      handleSubmitNote={handleSubmit}
      content={content}
    />
  ) : (
    <DisplayNote content={content} handleEdit={toggleEditNote} />
  );
};
