import { ConsultationDetails } from "../utils";
import { api } from "./axios";

export const createConsultationDetails = async (
  patientId: string,
  price: number,
) => {
  const { data } = await api.post<ConsultationDetails>(
    `/v1/team-members/patients/${patientId}/consultation-details`,
    { price },
  );

  return data;
};
