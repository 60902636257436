import { AlertIcon } from "~/common/components/icons/AlertIcon";

interface RenderRequiredTextProps {
  status?: boolean;
  alertIcon?: boolean;
}

export const RenderRequiredText = ({
  status,
  alertIcon,
}: RenderRequiredTextProps) =>
  status ? (
    <div className="flex items-center gap-1">
      <span className="text-primary-600">Required</span>
      {alertIcon && <AlertIcon />}
    </div>
  ) : (
    "-"
  );
