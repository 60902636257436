export const SCRIPT_FILTERS = {
  patient_name: "name",
  owner_id: "owner_id",
  page: "page",
  status: "status",
  unassigned: "unassigned",
} as const;

export const SCRIPT_FILTERS_VALUES = [
  SCRIPT_FILTERS.patient_name,
  SCRIPT_FILTERS.owner_id,
  SCRIPT_FILTERS.page,
  SCRIPT_FILTERS.status,
  SCRIPT_FILTERS.unassigned,
] as const;
