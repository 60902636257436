import { Control, FieldErrors, UseFormRegister } from "react-hook-form";

import DatePicker from "~/common/components/ui/DatePicker";
import Select from "~/common/components/ui/select/Select";
import { getTodayDate } from "~/common/utils/getTodayDate";
import { durationOptions } from "~/providers/constants";
import { AddOrderFormValues } from "./AddOrderForm";

interface ScheduleAppointmentInputsProps {
  register: UseFormRegister<AddOrderFormValues>;
  errors?: FieldErrors<AddOrderFormValues>;
  control: Control<AddOrderFormValues>;
}

export const ScheduleAppointmentInputs = ({
  register,
  errors,
  control,
}: ScheduleAppointmentInputsProps) => {
  const today = getTodayDate();

  return (
    <div className="flex w-full flex-col items-start gap-3">
      <Select
        label="Appointment duration"
        placeholder="Ex: 5 minutes"
        options={durationOptions}
        id="duration"
        control={control}
        error={
          errors && "duration" in errors ? errors?.duration?.message : undefined
        }
      />
      <DatePicker
        label="Due date"
        min={today}
        id="dueDate"
        {...register("dueDate")}
        error={
          errors && "dueDate" in errors ? errors.dueDate?.message : undefined
        }
      />
    </div>
  );
};
