import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { deletePendingOrderAndRelated } from "~/providers/api/patientProfileService";

interface DeleteOrderAndRelatedParams {
  patientId: string;
  orderId?: string;
  handleCloseDrawer: () => void;
}

export const useDeleteOrderAndRelated = ({
  patientId,
  orderId,
  handleCloseDrawer,
}: DeleteOrderAndRelatedParams) => {
  const queryClient = useQueryClient();

  const { mutate: deleteOrderAndRelatedMutation, isPending } = useMutation({
    mutationFn: () => deletePendingOrderAndRelated(patientId, orderId ?? ""),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPendingPatientInteractions"],
      });
      handleCloseDrawer();
    },
    onError: () =>
      toast.error("We had trouble deleting this order, try again later."),
  });

  return {
    deleteOrderAndRelatedMutation,
    isPending,
  };
};
