import { useQuery } from "@tanstack/react-query";

import { getMedicationsFrequencies } from "~/providers/api/patientMedicationsService";

export const useGetMedicationsFrequencies = (enabled: boolean) => {
  const { data, isLoading } = useQuery({
    queryFn: getMedicationsFrequencies,
    queryKey: ["getMedicationsFrequencies"],
    enabled,
  });
  return { data, isLoading };
};
