import { PropsWithChildren, ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";

import { Button } from "~/common/components";
import { ChevronIcon } from "~/common/components/icons";
import { DIRECTIONS } from "~/common/constants";
import { PATIENT_MEDICATION_STATUS } from "~/common/constants/medications";
import { PatientMedicationStatus } from "~/common/types";
import { DescriptiveTag } from "~/providers/components/DescriptiveTag";
import { useTeamMemberStore } from "~/providers/stores/useTeamMemberStore";

interface MedicationCardLayoutProps extends PropsWithChildren {
  status: PatientMedicationStatus;
  buttonsDisabled?: boolean;
  title: ReactNode;
  subtitle: string;
  handleModify: () => void;
  handleDiscontinue: () => void;
  pausedScriptDate?: string;
}

export const MedicationCardLayout = ({
  status,
  buttonsDisabled = false,
  title,
  subtitle,
  handleModify,
  handleDiscontinue,
  pausedScriptDate,
  children,
}: MedicationCardLayoutProps) => {
  const [seeDetails, setSeeDetails] = useState(false);
  const { isStaff } = useTeamMemberStore();

  const toggleSeeDetails = () => setSeeDetails((prev) => !prev);

  const discontinuedStatus = status === PATIENT_MEDICATION_STATUS.discontinued;

  return (
    <div
      className={twMerge(
        "flex flex-col gap-4 rounded-lg border  border-primary-200 bg-primary-50 p-4 text-gray-70 transition-all duration-500",
        discontinuedStatus && "border-neutral-700 bg-neutral-25",
      )}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col gap-1">
          <p className="text-sm  font-medium leading-5 tracking-wide text-gray-10">
            {subtitle}
          </p>
          <h3 className="text-lg font-semibold capitalize leading-5 text-gray-70">
            {title}
          </h3>
        </div>
        <div className="flex gap-3">
          {status === PATIENT_MEDICATION_STATUS.active && (
            <div className="flex items-center gap-4">
              {pausedScriptDate && <DescriptiveTag />}
              {!isStaff && (
                <div className="flex gap-2">
                  <Button
                    variant="outline-black"
                    size="sm"
                    className="bg-neutral-25"
                    onClick={handleDiscontinue}
                    disabled={buttonsDisabled}
                  >
                    Discontinue
                  </Button>
                  <Button
                    variant="outline-black"
                    size="sm"
                    className="bg-neutral-25"
                    onClick={handleModify}
                    disabled={buttonsDisabled}
                  >
                    Modify
                  </Button>
                </div>
              )}
            </div>
          )}
          <button className="w-fit" onClick={toggleSeeDetails}>
            <ChevronIcon
              direction={seeDetails ? DIRECTIONS.top : DIRECTIONS.down}
              className="h-3.5 text-gray-70"
            />
          </button>
        </div>
      </div>
      {seeDetails && children}
    </div>
  );
};
