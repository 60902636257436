export const generateDailyDoseValue = ({
  strengthAmount,
  maxTake,
  frequency,
}: {
  strengthAmount: number;
  maxTake: number;
  frequency: number;
}) => {
  const value = strengthAmount * maxTake * frequency;
  return isNaN(value) ? "N/A" : value + " mg";
};
