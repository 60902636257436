import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils";

export const CameraIcon = ({ className, ...props }: IconProps) => {
  return (
    <svg
      className={twMerge("size-4 text-white", className)}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 4.50007C6.58616 4.5 6.67501 4.5 6.76667 4.5H10.2333C10.325 4.5 10.4138 4.5 10.5 4.50007M6.5 4.50007C5.1822 4.50109 4.49145 4.51784 3.95603 4.79065C3.45426 5.04631 3.04631 5.45426 2.79065 5.95603C2.5 6.52646 2.5 7.27319 2.5 8.76667V9.56667C2.5 11.0601 2.5 11.8069 2.79065 12.3773C3.04631 12.8791 3.45426 13.287 3.95603 13.5427C4.52646 13.8333 5.27319 13.8333 6.76667 13.8333H10.2333C11.7268 13.8333 12.4735 13.8333 13.044 13.5427C13.5457 13.287 13.9537 12.8791 14.2094 12.3773C14.5 11.8069 14.5 11.0601 14.5 9.56667V8.76667C14.5 7.27319 14.5 6.52646 14.2094 5.95603C13.9537 5.45426 13.5457 5.04631 13.044 4.79065C12.5086 4.51784 11.8178 4.50109 10.5 4.50007M6.5 4.50007V3.83333C6.5 3.09695 7.09695 2.5 7.83333 2.5H9.16667C9.90305 2.5 10.5 3.09695 10.5 3.83333V4.50007M10.5 9.16667C10.5 10.2712 9.60457 11.1667 8.5 11.1667C7.39543 11.1667 6.5 10.2712 6.5 9.16667C6.5 8.0621 7.39543 7.16667 8.5 7.16667C9.60457 7.16667 10.5 8.0621 10.5 9.16667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
