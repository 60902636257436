export const scrollToBottom = (id: string) => {
  const section = document.getElementById(id);
  if (section) {
    setTimeout(() => {
      section.scrollIntoView({
        behavior: "smooth",
      });
    }, 0);
  }
};
