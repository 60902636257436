import { Option, TeamMember } from "~/common/utils";
import { OWNER_OPTIONS } from "../constants";

export const generateTeamMemberOptions = (
  staffList?: TeamMember[],
): Option[] => [
  {
    label: "Unassigned",
    value: OWNER_OPTIONS.unassigned,
  },
  ...(staffList?.map(({ id, name, last_name }) => ({
    label: `${name} ${last_name}`,
    value: id,
  })) ?? []),
];
