import { getIntervalString } from "~/common/utils/checkIn/getOrderRepetition";
import { formatDate } from "~/providers/utils/orders/getDateText";
import { Order } from "~/providers/utils/types/interactions";

interface ScheduleCheckinsDataProps {
  order: Order;
}
export const ScheduleCheckinsData = ({ order }: ScheduleCheckinsDataProps) => {
  return (
    <div className="flex flex-col gap-4 pt-6">
      <div className="flex flex-row gap-2">
        <p className="font-light text-gray-10">Repeat this order</p>
        {order.frequency && order.interval && (
          <p>{getIntervalString(order.frequency, order.interval)}</p>
        )}
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-2">
          <p className="font-light text-gray-10">Next check-in due</p>
          <p>{formatDate(order.due_date)}</p>
        </div>
        {order.end_date && (
          <div className="flex flex-row gap-2">
            <p className="font-light text-gray-10">Repeats until</p>
            <p>{formatDate(order.end_date)}</p>
          </div>
        )}
      </div>
    </div>
  );
};
