import { useQuery } from "@tanstack/react-query";

import { getScriptComments } from "~/providers/api/commentService";

export const useGetScriptComments = (scriptId?: string) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getScriptComments(scriptId ?? ""),
    queryKey: ["getComments", scriptId],
    enabled: !!scriptId,
  });
  return { data, isLoading };
};
