import { useQuery } from "@tanstack/react-query";

import { getScriptsList } from "~/providers/api/scriptsService";

export const useGetScriptsList = (
  params: URLSearchParams,
  enabled?: boolean,
) => {
  const { data, isLoading, isFetched } = useQuery({
    queryFn: () => getScriptsList(params),
    queryKey: ["getScriptsList", Object.fromEntries(params)],
    enabled,
  });
  return { data, isLoading, isFetched };
};
