import { Intervals, INTERVALS } from "../types/checkInTypes";

const numberWords = [
  "",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
  "eleven",
  "twelve",
];

export const intervalMap = {
  [INTERVALS.daily]: "day",
  [INTERVALS.weekly]: "week",
  [INTERVALS.monthly]: "month",
} as const;

export const getIntervalString = (frequency: number, interval: Intervals) => {
  const intervalString = intervalMap[interval];
  const pluralSuffix = frequency > 1 ? "s" : "";
  const displayFrequency = frequency <= 12 ? numberWords[frequency] : frequency;
  return `Every ${displayFrequency} ${intervalString}${pluralSuffix}`;
};
