import { CHECK_IN_CODES, CHECKIN_STATUS } from "~/common/utils";
import { getIconByCode, isBundleFeedContext } from "~/providers/utils";
import { PatientFeed } from "~/providers/utils/types/interactions";
import { ActivityFeedCard } from "./ActivityFeedCard";
import { CompletedCheckInActivityCard } from "./CompletedCheckInActivityCard";

interface PatientCheckInActivityCardProps {
  patientActivity: PatientFeed;
  setSelectedPatientInteractionId: (id: string) => void;
}

export const PatientCheckInActivityCard = ({
  patientActivity,
  setSelectedPatientInteractionId,
}: PatientCheckInActivityCardProps) => {
  const { id, context, description, created_at, related_model_id } =
    patientActivity;
  const isScheduleAppointment =
    !isBundleFeedContext(context) &&
    context.checkin_code === CHECK_IN_CODES.schedule_appointment;

  if (isScheduleAppointment)
    return (
      <ActivityFeedCard
        key={id}
        icon={getIconByCode(context.checkin_code)()}
        title={description}
        date={created_at}
      />
    );

  if (context.status === CHECKIN_STATUS.completed)
    return (
      <CompletedCheckInActivityCard
        key={id}
        patientActivity={patientActivity}
        handleViewCheckIn={() =>
          setSelectedPatientInteractionId(related_model_id)
        }
      />
    );

  return (
    <ActivityFeedCard
      key={id}
      title={description}
      date={created_at}
      icon={
        !isBundleFeedContext(context) && getIconByCode(context.checkin_code)()
      }
      className={
        context.status === CHECKIN_STATUS.missed
          ? "border-orange-20 bg-orange-25"
          : ""
      }
    />
  );
};
