import { UpsertMedicationFormValues } from "../components/patientProfile/medications";
import { formatMedicationDetailsData, formatNewMedicationData } from "../utils";
import { useCreateMedication } from "./queries";
import { useModifyMedication } from "./queries/medications/useModifyMedication";

interface UseUpsertPatientMedicationProps {
  onSuccess: () => void;
  patientId: string;
  updateMedication: boolean;
  defaultPatientMedicationId?: string;
}

export const useUpsertPatientMedication = ({
  patientId,
  onSuccess,
  updateMedication,
  defaultPatientMedicationId,
}: UseUpsertPatientMedicationProps) => {
  const { createMedicationMutation, isPending: createPending } =
    useCreateMedication({
      onSuccess,
    });

  const { modifyMedicationMutation, isPending: updatePending } =
    useModifyMedication({
      onSuccess,
    });

  const upsertPatientMedication = (data: UpsertMedicationFormValues) => {
    if (updateMedication && defaultPatientMedicationId) {
      const medicationDetails = formatMedicationDetailsData(data);
      modifyMedicationMutation({
        patientId,
        patientMedicationId: defaultPatientMedicationId,
        medicationDetails,
      });
      return;
    }

    const medicationData = formatNewMedicationData(data);
    createMedicationMutation({ patientId, medicationData });
  };

  const isPending = createPending || updatePending;

  return { upsertPatientMedication, isPending };
};
