import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

import AuthenticationLayout from "~/common/components/layouts/AuthenticationLayout";
import FormLayout from "~/common/components/layouts/FormLayout";
import Input from "~/common/components/ui/Input";
import { LOGIN_ERRORS } from "~/common/constants";
import { ErrorResponse, getCurrentTeamMember, login } from "../api";
import { providersWelcomeText } from "../constants";
import { ROUTES } from "../router";
import { useTeamMemberStore } from "../stores/useTeamMemberStore";
import { authenticateProviderSchema } from "../utils/ProviderSchemas";

type LogInFormValues = z.infer<typeof authenticateProviderSchema>;
export const Login = () => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm<LogInFormValues>({
    resolver: zodResolver(authenticateProviderSchema),
  });
  const { setTeamMember } = useTeamMemberStore();
  const navigate = useNavigate();

  const { mutate: loginMutation, isPending } = useMutation({
    mutationFn: login,
    onError: (error: ErrorResponse) => {
      if (error.response?.status === 401) {
        setError("email", {
          type: "custom",
          message: LOGIN_ERRORS.INVALID_CREDENTIALS,
        });
        setError("password", {
          type: "custom",
          message: LOGIN_ERRORS.INVALID_CREDENTIALS,
        });
      } else {
        toast.error("Oops, there was an issue logging in, try again later!");
      }
    },
    onSuccess: async () => {
      const teamMemberData = await getCurrentTeamMember();
      setTeamMember(teamMemberData);
      navigate(ROUTES.home);
    },
  });

  const onSubmit: SubmitHandler<LogInFormValues> = (data) => {
    loginMutation(data);
  };

  return (
    <AuthenticationLayout welcomeText={providersWelcomeText}>
      <FormLayout
        handleSubmit={handleSubmit(onSubmit)}
        submitLabel="Log in"
        title="Log in"
        pendingRequest={isPending}
      >
        <Input
          id="email"
          label="Email address"
          {...register("email")}
          error={errors.email?.message}
        />
        <div className="flex w-full flex-col items-end gap-2">
          <Input
            id="password"
            type="password"
            label="Password"
            {...register("password")}
            error={errors.password?.message}
          />
          <Link
            to={ROUTES.forgotPassword}
            className="border-b border-gray-500 text-sm"
          >
            Forgot password
          </Link>
        </div>
      </FormLayout>
    </AuthenticationLayout>
  );
};
