export const generatePrescribedDaysValue = ({
  quantity,
  maxTake,
  frequency,
}: {
  quantity: number;
  maxTake: number;
  frequency: number;
}) => {
  const value = quantity / (maxTake * frequency);
  return isNaN(value) || !Number.isFinite(value) ? "N/A" : value.toFixed(2);
};
