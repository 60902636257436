import { getIconByCode, isBundleFeedContext } from "~/providers/utils";
import { PatientFeed } from "~/providers/utils/types/interactions";
import { ActivityFeedCard } from "./ActivityFeedCard";

interface BundleActivityCardProps {
  patientActivity: PatientFeed;
}
export const BundleActivityCard = ({
  patientActivity,
}: BundleActivityCardProps) => {
  const { context, created_at } = patientActivity;
  if (!isBundleFeedContext(context)) return;

  const bundleCheckInCode = context.bundle.patient_checkins[0].checkin.code;

  return (
    <ActivityFeedCard
      title={`New ${context.bundle.patient_medication.medication.type} medication change order assigned`}
      date={created_at}
      icon={getIconByCode(bundleCheckInCode)()}
    />
  );
};
