import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { UpdateNoteData } from "~/common/utils/types";
import { updatePatientNote } from "~/providers/api/patientMedicationsService";

export const useUpdatePatientNoteMutation = (
  patientId: string,
  patientNoteId: string,
) => {
  const queryClient = useQueryClient();

  const { mutateAsync: updatePatientNoteMutation, isPending } = useMutation({
    mutationFn: (note: UpdateNoteData) =>
      updatePatientNote(note, patientId, patientNoteId),
    onError: () =>
      toast.error(
        "There was an issue updating the note. Please try again later.",
      ),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["getPatientNote"] })
        .catch(() => {
          toast.error("Failed to refresh patient data.");
        });
    },
  });

  return { isPending, updatePatientNoteMutation };
};
