import { CalendarIcon, ChevronIcon } from "~/common/components/icons";
import { DIRECTIONS } from "~/common/constants";
import { checkInUtilities } from "~/common/utils/checkIn/checkInUtilities";
import {
  CHECK_IN_CODES,
  CheckInCodes,
  InteractionCreationTypes,
} from "~/common/utils/types/checkInTypes";
import { checkInBasicData } from "~/providers/constants";
import { orderTypeIcon } from "~/providers/utils/orders";
import { formatDate } from "~/providers/utils/orders/getDateText";

interface OrderCardProps {
  handleViewOrder?: () => void;
  type: InteractionCreationTypes;
  description: CheckInCodes;
  due_date: string;
  created_at: string;
  code?: CheckInCodes;
}
export const OrderCard = ({
  handleViewOrder,
  type,
  description,
  due_date,
  created_at,
  code,
}: OrderCardProps) => {
  const { formatCheckInCode } = checkInUtilities();
  const isScheduleAppointment = code === CHECK_IN_CODES.schedule_appointment;

  return (
    <button
      className="flex w-full items-center gap-4 rounded-lg border border-neutral-700 px-4 py-2"
      onClick={handleViewOrder}
    >
      <div className="flex w-full flex-col items-start">
        <div className="flex w-full justify-between">
          <div className="flex items-center gap-2">
            {checkInBasicData[description] && (
              <div className="flex">{checkInBasicData[description].icon()}</div>
            )}
            <p className="text-base  text-gray-70">
              {formatCheckInCode(description) ?? ""}
            </p>
            {!isScheduleAppointment && orderTypeIcon[type]}
          </div>
          <div className="flex w-1/2 justify-around">
            <div className="flex items-center justify-start gap-2">
              <CalendarIcon />
              <p className="text-sm font-light text-gray-30">
                Next check-in due {formatDate(due_date)}
              </p>
            </div>
            <div className="flex items-center text-sm font-light italic text-gray-30">
              Created on {formatDate(created_at)}
            </div>
          </div>
        </div>
      </div>
      <ChevronIcon
        direction={DIRECTIONS.right}
        className="h-3 w-3 text-gray-70"
      />
    </button>
  );
};
