export interface FieldData {
  title: string;
  content: string;
}

export interface InfoCardProps {
  title?: string;
  fieldData: FieldData[];
}

const InfoCard = ({ title, fieldData }: InfoCardProps) => {
  return (
    <div className="flex w-full flex-col gap-3 rounded-lg border p-4 shadow-inner shadow-gray-50">
      <h1 className="text-lg font-semibold text-gray-800">{title}</h1>
      <div className="grid w-full grid-cols-4">
        {fieldData?.map((field, index) => (
          <div className="flex flex-col gap-1" key={index}>
            <h1 className="text-[0.94rem] font-medium text-gray-500">
              {field.title}
            </h1>
            <p className="line-clamp-2 break-words text-base">
              {field.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoCard;
