import { PauseMedicationFormValuesType } from "./scriptSchemas";

export const getModifiedScripts = (
  defaultValues: PauseMedicationFormValuesType[],
  medicationsValues: PauseMedicationFormValuesType[],
) =>
  medicationsValues.filter(
    ({ id, paused }) =>
      !!defaultValues.find(
        ({ id: defaultValueId, paused: defaultValuePaused }) =>
          defaultValueId === id && paused !== defaultValuePaused,
      ),
  );
