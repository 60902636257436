import { useState } from "react";

import { Button, LoadingState, SectionHeader } from "~/common/components";
import { PATIENT_MEDICATION_STATUS } from "~/common/constants/medications";
import { useGetPatientMedications } from "~/providers/hooks";
import { useTeamMemberStore } from "~/providers/stores/useTeamMemberStore";
import { ActiveMedications } from "./ActiveMedications";
import { PrescriptionPermissionModal } from "./PrescriptionPermissionModal";
import { UpsertMedicationModal } from "./UpsertMedicationModal";

interface CurrentMedicationsProps {
  patientId: string;
}

export const CurrentMedications = ({ patientId }: CurrentMedicationsProps) => {
  const { isStaff } = useTeamMemberStore();

  const [addMedication, setAddMedication] = useState(false);
  const [showScriptPermissionsModal, setShowScriptPermissionsModal] =
    useState(false);
  const toggleAddMedication = () => setAddMedication((prev) => !prev);
  const toggleShowScriptsModal = () =>
    setShowScriptPermissionsModal((prev) => !prev);
  const { data: activePatientMedications, isLoading } =
    useGetPatientMedications(PATIENT_MEDICATION_STATUS.active, patientId);

  const { data: activeMedications } = { ...activePatientMedications };
  const activeMedicationsLength = activeMedications?.length ?? 0;

  const pausedAmount =
    activeMedications?.filter(({ paused_scripts_at }) => !!paused_scripts_at)
      .length ?? 0;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex justify-between">
        <SectionHeader>Current Medications</SectionHeader>
        {!isStaff && (
          <div className="flex gap-2">
            <Button
              size="sm"
              variant="outline-black"
              className="w-fit"
              onClick={toggleShowScriptsModal}
              disabled={!activeMedications?.length}
            >
              Prescription permissions
            </Button>
            <Button size="sm" className="w-fit" onClick={toggleAddMedication}>
              Add new medication
            </Button>
          </div>
        )}
      </div>
      {isLoading && <LoadingState />}
      <ActiveMedications medications={activeMedications} />
      <UpsertMedicationModal
        handleClose={toggleAddMedication}
        show={addMedication}
        patientId={patientId}
      />
      <PrescriptionPermissionModal
        patientId={patientId}
        medications={activeMedications ?? []}
        show={showScriptPermissionsModal}
        onClose={toggleShowScriptsModal}
        key={pausedAmount + activeMedicationsLength}
      />
    </div>
  );
};
