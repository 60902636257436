import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { EmptyState } from "../EmptyState";

interface EmptyTableStateProps
  extends React.TdHTMLAttributes<HTMLTableCellElement> {
  message: string;
  icon?: ReactNode;
  className?: string;
}

export const EmptyTableState = ({
  message,
  icon,
  className,
  ...props
}: EmptyTableStateProps) => (
  <tr>
    <td className={twMerge("border-t", className)} {...props}>
      <EmptyState message={message} icon={icon} />
    </td>
  </tr>
);
