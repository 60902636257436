import { formatFrequenciesOptions, formatMedicationsOptions } from "../utils";
import { useGetMedicationLists, useGetMedicationsFrequencies } from "./queries";

export const useGetMedicationSelectOptions = (enabled: boolean) => {
  const { data: frequenciesList, isLoading: loadingFrequencies } =
    useGetMedicationsFrequencies(enabled);
  const { data: medicationsLists, isLoading: loadingMedications } =
    useGetMedicationLists(enabled);

  const medicationsOptions = formatMedicationsOptions(medicationsLists?.data);
  const frequenciesOptions = formatFrequenciesOptions(frequenciesList?.data);

  const loadingSelectOptions = loadingFrequencies || loadingMedications;

  return {
    frequenciesList,
    medicationsLists,
    medicationsOptions,
    frequenciesOptions,
    loadingSelectOptions,
  };
};
