import { INTERACTION_CREATION_TYPES } from "~/common/utils/types/checkInTypes";
import { Interaction, Order, PatientSchedule } from "../types/interactions";

export const mergeAndSortScheduleAndInteractionsArrays = (
  interactions: Interaction[],
  patientSchedules: PatientSchedule[],
): Order[] => {
  const transformedInteractions: Order[] = interactions.map((interaction) => ({
    id: interaction.id,
    description: interaction.description,
    checkin: interaction.checkin,
    type: interaction.creation_type,
    due_date: interaction.due_date,
    extra_data: interaction.extra_data,
    created_at: interaction.created_at,
    start_date: interaction.start_date,
    note: interaction.note,
    internal_note: interaction.internal_note,
  }));

  const transformedPatientSchedules: Order[] = patientSchedules.map(
    (schedule) => ({
      id: schedule.id,
      description: schedule.checkin.code,
      type: INTERACTION_CREATION_TYPES.schedule,
      due_date: schedule.next_interaction_due_date,
      end_date: schedule.end_date,
      created_at: schedule.created_at,
      start_date: schedule.start_date,
      note: schedule.note,
      internal_note: schedule.internal_note,
      frequency: schedule.frequency,
      interval: schedule.interval,
    }),
  );

  const combinedArray = [
    ...transformedInteractions,
    ...transformedPatientSchedules,
  ];

  combinedArray.sort((a, b) => {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();
    return dateB - dateA;
  });

  return combinedArray;
};
