import { MedicationInformation, NewMedicationData } from "~/common/types";
import { UpsertMedicationFormValues } from "~/providers/components/patientProfile/medications";
import { PRESCRIBE_ORDER_OPTIONS } from "~/providers/constants";

export const formatMedicationDetailsData = (
  data: UpsertMedicationFormValues,
): MedicationInformation => {
  const { take, take_max, range, submit_prescription_order } =
    data.patient_medications_detail;
  const takeString = range && take_max ? `${take} to ${take_max}` : `${take}`;

  const medicationDetails: MedicationInformation = {
    ...data.patient_medications_detail,
    take: takeString,
    submit_prescription_order:
      submit_prescription_order === PRESCRIBE_ORDER_OPTIONS.true.value,
  };
  return medicationDetails;
};

export const formatNewMedicationData = (
  data: UpsertMedicationFormValues,
): NewMedicationData => {
  return {
    medication_id: data.medication_id,
    start_date: data.patient_medications_detail.start_date,
    patient_medications_detail: formatMedicationDetailsData(data),
  };
};
