import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

import { Option } from "~/common/utils";
import { MultiSelectListBox } from "./MultiSelectListBox";

interface MultiSelectProps<TFieldValues extends FieldValues> {
  control?: Control<TFieldValues>;
  label?: string;
  name: FieldPath<TFieldValues>;
  options: Option[];
  placeholder: string;
  description?: string;
  value: string[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
  containerClassName?: string;
  clearAllLabel?: string;
}

export const MultiSelect = <TFieldValues extends FieldValues>({
  options,
  placeholder,
  description,
  onChange,
  value,
  control,
  name,
  containerClassName,
  clearAllLabel,
  disabled = false,
}: MultiSelectProps<TFieldValues>) =>
  control ? (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange: controllerOnChange, value: formValue },
      }) => {
        const value = formValue as string[];
        return (
          <MultiSelectListBox
            defaultValues={value}
            placeholder={placeholder}
            controllerOnChange={controllerOnChange}
            options={options}
            description={description}
            handleClearAll={() => controllerOnChange([])}
            disabled={disabled}
            containerClassName={containerClassName}
            clearAllLabel={clearAllLabel}
          />
        );
      }}
    />
  ) : (
    <MultiSelectListBox
      options={options}
      placeholder={placeholder}
      description={description}
      defaultValues={value}
      onChange={onChange}
      handleClearAll={() => onChange([])}
      disabled={disabled}
      containerClassName={containerClassName}
      clearAllLabel={clearAllLabel}
    />
  );
