import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  addBundle,
  addCheckIn,
  addSchedule,
} from "~/providers/api/patientProfileService";

interface AddOrderMutationProps {
  handleCloseModal: () => void;
}

export const useAddOrderMutations = ({
  handleCloseModal,
}: AddOrderMutationProps) => {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    handleCloseModal();
    await queryClient.invalidateQueries({
      queryKey: ["getPendingPatientInteractions"],
    });
    await queryClient.invalidateQueries({
      queryKey: ["getPatientSchedules"],
    });
  };

  const onError = (error: Error) => toast.error(error.message);

  const { mutate: addBundleMutation, isPending: isBundlePending } = useMutation(
    {
      mutationFn: addBundle,
      onSuccess,
      onError,
    },
  );

  const { mutate: addCheckInMutation, isPending: isCheckinPending } =
    useMutation({
      mutationFn: addCheckIn,
      onSuccess,
      onError,
    });

  const { mutate: addScheduleMutation, isPending: isSchedulePending } =
    useMutation({
      mutationFn: addSchedule,
      onSuccess,
      onError,
    });

  return {
    addBundleMutation,
    addCheckInMutation,
    addScheduleMutation,
    isBundlePending,
    isCheckinPending,
    isSchedulePending,
  };
};
