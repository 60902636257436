import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";

import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import { MedicationBasicData, MedicationFrequency } from "~/common/types";
import { Option } from "~/common/utils";
import CheckBox from "~/patients/components/ui/CheckBox";
import {
  formatStrengthOptions,
  generateDailyDoseValue,
  generatePrescribedDaysValue,
  getSelectedMedication,
  getSelectedMedicationFrequency,
  getSelectedStrengthAmount,
} from "~/providers/utils";
import { UpsertMedicationFormValues } from "../UpsertMedicationModal";
import { TakeInputs } from "./TakeInputs";

interface MedicationDetailsInputsProps {
  watch: UseFormWatch<UpsertMedicationFormValues>;
  control: Control<UpsertMedicationFormValues>;
  register: UseFormRegister<UpsertMedicationFormValues>;
  errors: FieldErrors<UpsertMedicationFormValues>;
  updateMedication: boolean;
  medicationsOptions: Option[];
  frequenciesOptions: Option[];
  medicationsLists?: MedicationBasicData[];
  frequenciesList?: MedicationFrequency[];
}

export const MedicationDetailsInputs = ({
  watch,
  control,
  register,
  errors,
  updateMedication,
  frequenciesOptions,
  medicationsOptions,
  medicationsLists,
  frequenciesList,
}: MedicationDetailsInputsProps) => {
  const maxTake =
    watch("patient_medications_detail.range") &&
    watch("patient_medications_detail.take_max")
      ? Number(watch("patient_medications_detail.take_max"))
      : Number(watch("patient_medications_detail.take"));

  const quantity =
    Number(watch("patient_medications_detail.prescribed_quantity")) ?? 0;
  const selectedFrequencyId = watch("patient_medications_detail.frequency_id");

  const frequency =
    getSelectedMedicationFrequency(selectedFrequencyId, frequenciesList)
      ?.per_day ?? 0;

  const prescribedDaysString = generatePrescribedDaysValue({
    quantity,
    maxTake,
    frequency,
  });

  const selectedMedication = getSelectedMedication(
    watch("medication_id"),
    medicationsLists,
  );

  const selectedStrengths = formatStrengthOptions(
    selectedMedication?.medication_strengths,
  );

  const strengthAmount = getSelectedStrengthAmount(
    watch("patient_medications_detail.medication_strength_id"),
    selectedMedication?.medication_strengths,
  );

  const dailyDoseString = generateDailyDoseValue({
    strengthAmount,
    maxTake,
    frequency,
  });

  return (
    <div className="flex h-full w-full flex-col gap-3 border-b border-neutral-700 pb-3">
      <div className="flex w-full items-start gap-3">
        <Select
          label="Medication"
          placeholder="Select medication"
          id="medication_id"
          options={medicationsOptions}
          control={control}
          containerClassName="gap-2 w-1/2"
          error={errors.medication_id?.message}
          isDisabled={updateMedication}
        />
        <Select
          id="patient_medications_detail.medication_strength_id"
          options={selectedStrengths}
          label="Strength"
          isDisabled={!selectedMedication}
          placeholder="Select the strength"
          containerClassName="gap-2 w-1/2"
          control={control}
          error={
            errors.patient_medications_detail?.medication_strength_id?.message
          }
        />
      </div>
      <div className="flex w-full items-start gap-3">
        <TakeInputs register={register} watch={watch} errors={errors} />
        <div className="flex w-1/2 flex-col gap-2">
          <div className="flex justify-between">
            <p className="text-sm font-medium text-gray-70">Frequency</p>
            <div>
              <CheckBox
                labelClassName="text-sm"
                className="h-4 w-4"
                id="as_needed"
                {...register("patient_medications_detail.as_needed")}
              >
                As needed
              </CheckBox>
            </div>
          </div>
          <Select
            id="patient_medications_detail.frequency_id"
            control={control}
            options={frequenciesOptions}
            placeholder="Select the frequency"
            containerClassName="gap-2"
            error={errors.patient_medications_detail?.frequency_id?.message}
          />
        </div>
      </div>
      <div className="flex w-full items-start gap-3">
        <Input
          id="quantity"
          label="Quantity prescribed"
          {...register("patient_medications_detail.prescribed_quantity")}
          error={
            errors.patient_medications_detail?.prescribed_quantity?.message
          }
        />
        <Input
          id="refills"
          label="Number of refills"
          {...register("patient_medications_detail.number_of_refills")}
          error={errors.patient_medications_detail?.number_of_refills?.message}
        />
      </div>
      <div className="flex w-full items-start gap-3">
        <Input
          placeholder=""
          id="prescribed_days"
          label="Prescribed days"
          value={prescribedDaysString}
          disabled
        />
        <Input
          id="dailyDose"
          label="Daily dose"
          placeholder=""
          disabled
          value={dailyDoseString}
        />
      </div>
    </div>
  );
};
