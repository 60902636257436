import { EmptyState } from "~/common/components";
import { PATIENT_MEDICATION_STATUS } from "~/common/constants/medications";
import { PatientMedication } from "~/common/types";
import { MedicationCard } from "./card/MedicationCard";

interface MedicationsProps {
  medications?: PatientMedication[];
}

export const ActiveMedications = ({ medications }: MedicationsProps) => {
  if (medications && !medications.length) {
    return (
      <EmptyState message="No medications found" className="h-80 border-t" />
    );
  }

  return medications?.map((med) => (
    <MedicationCard
      {...med}
      status={PATIENT_MEDICATION_STATUS.active}
      key={med.id}
    />
  ));
};
