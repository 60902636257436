import { Patient } from "~/common/utils";
import { ConsultationsRequestsRowProps } from "~/providers/components/consultationRequests/ConsultationsRequestsRow";

export const formatConsultationRequestRowData = (
  patient: Patient,
): ConsultationsRequestsRowProps => {
  const { id, name, last_name, last_feed } = { ...patient };

  return {
    id,
    patientName: `${name} ${last_name}`,
    description: last_feed?.description,
    unreviewed: !last_feed?.reviewed_at,
    date: last_feed?.created_at,
  };
};
