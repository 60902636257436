import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { archiveLeadPatient } from "~/providers/api";

export const useArchiveLeadPatient = (
  onSuccess: () => void | Promise<void>,
  onError?: () => void,
) => {
  const { mutate: archivePatientMutation } = useMutation({
    mutationFn: archiveLeadPatient,
    onSuccess,
    onError: () => {
      onError ??
        toast.error(
          "There was an error archiving your patient. If the issue persists, please reload the page.",
        );
    },
  });

  return { archivePatientMutation };
};
