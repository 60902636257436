import { INTERACTION_CREATION_TYPES } from "~/common/utils/types/checkInTypes";
import {
  BundleFormValues,
  CheckInFormValues,
  ScheduleAppointmentFormValues,
  ScheduleFormValues,
} from "../components/patientProfile/orderPanel/AddOrderForm";
import { frequencies } from "../constants/ordersFrequencies";
import {
  BundleData,
  NewCheckInData,
  ScheduleData,
} from "../utils/types/interactions";
import { useAddOrderMutations } from "./queries/useAddOrderMutations";

interface AddOrderProps {
  handleCloseModal: () => void;
  patientId: string;
}
export const useAddOrder = ({ handleCloseModal, patientId }: AddOrderProps) => {
  const {
    addBundleMutation,
    addCheckInMutation,
    addScheduleMutation,
    isBundlePending,
    isCheckinPending,
    isSchedulePending,
  } = useAddOrderMutations({
    handleCloseModal,
  });

  const isPending = isBundlePending || isCheckinPending || isSchedulePending;

  const handleAddBundle = (data: BundleFormValues, orderId: string) => {
    const orderData: BundleData = {
      start_date: data.startDate,
      patient_medication_id: data.patientMedicationId,
      note: data.patientNote,
      internal_note: data.internalNote,
    };

    addBundleMutation({
      patientId,
      bundleId: orderId,
      orderData,
      creation_type: INTERACTION_CREATION_TYPES.bundle,
    });
  };

  const handleAddCheckIn = (
    data: CheckInFormValues | ScheduleAppointmentFormValues,
    checkInId: string,
  ) => {
    const orderData: NewCheckInData = {
      duration: "duration" in data ? data.duration : undefined,
      due_date: data.dueDate,
      note: data.patientNote,
      internal_note: data.internalNote,
      creation_type: INTERACTION_CREATION_TYPES.special_request,
    };

    addCheckInMutation({
      patientId,
      checkInId,
      orderData,
    });
  };

  const handleAddSchedule = (data: ScheduleFormValues, checkInId: string) => {
    const orderData: ScheduleData = {
      ...frequencies[data.frequency],
      end_date: data.endDate,
      due_date: data.dueDate,
      note: data.patientNote,
      internal_note: data.internalNote,
      creation_type: INTERACTION_CREATION_TYPES.schedule,
    };

    addScheduleMutation({
      patientId,
      checkInId,
      orderData,
    });
  };

  return {
    handleAddBundle,
    handleAddCheckIn,
    handleAddSchedule,
    isPending,
  };
};
