import { Option } from "~/common/utils";

export const UPSERT_PROFILE_IMAGE_OPTIONS = {
  edit: "edit",
  delete: "delete",
} as const;

export const getUpsertProfileImageOptions = (
  previewImage?: boolean,
): Option[] => [
  { label: "Edit image", value: UPSERT_PROFILE_IMAGE_OPTIONS.edit },
  {
    label: "Delete image",
    value: UPSERT_PROFILE_IMAGE_OPTIONS.delete,
    disabled: !previewImage,
  },
];
