import {
  ProviderConsultationFeeUpdate,
  ProviderPaymentSettings,
  ProviderPaymentSettingsUpdate,
} from "../utils/types/paymentSettingsTypes";
import { api } from "./axios";

export const updateConsultationFee = async ({
  updateConsultationFeeData,
  providerPaymentSettingsId,
}: ProviderConsultationFeeUpdate) => {
  const { data } = await api.patch<ProviderPaymentSettings>(
    `/v1/team-members/payment-settings/${providerPaymentSettingsId}/consultation-fee`,
    updateConsultationFeeData,
  );
  return data;
};

export const updatePaymentSettings = async ({
  updatePaymentSettingsData,
  providerPaymentSettingsId,
}: ProviderPaymentSettingsUpdate) => {
  const { data } = await api.patch<ProviderPaymentSettings>(
    `/v1/team-members/payment-settings/${providerPaymentSettingsId}/stripe-keys`,
    updatePaymentSettingsData,
  );
  return data;
};

export const getPaymentSettings = async () => {
  const { data } = await api.get<ProviderPaymentSettings>(
    "/v1/team-members/payment-settings/",
  );
  return data;
};
