import React from "react";

import Modal from "~/common/components/Modal";
import {
  StripeCreateRestrictedApiKey,
  StripeNavigateApiKeys,
  StripeRetrieveYourPublishableKey,
} from "~/providers/assets";

interface StripeInfoModalProps {
  show: boolean;
  onClose: () => void;
}

export const StripeInfoModal = ({ show, onClose }: StripeInfoModalProps) => (
  <Modal
    className="h-fit w-96"
    titleClassName="text-lg font-medium w-2/3 text-gray-70"
    title="Setting Up Stripe for Live Payments"
    show={show}
    onClose={onClose}
  >
    <div className="h-96 overflow-scroll">
      <div className="flex flex-col items-start gap-10">
        <div className="flex flex-col gap-4">
          <p className="text-sm">
            To start charging with Stripe on our platform, you will need the
            following credentials:
          </p>
          <ul className="list-disc pl-8 text-sm">
            <li>Publishable API Key</li>
            <li>Restricted API Key</li>
          </ul>
          <p className="text-sm">
            Before you begin this tutorial, please ensure that you have
            completed your business profile to accept live payments and have
            disabled test mode.
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-bold">Step 1: Disable Test Mode</h3>
          <p className="text-sm">
            Make sure you have disabled test mode in your Stripe account. This
            is crucial for processing live payments.
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-bold">
            Step 2: Access the Developers Tab
          </h3>
          <ul className="list-decimal pl-8 text-sm">
            <li>Log in to your Stripe account.</li>
            <li>
              Navigate to the Developers tab in the header and click on
              <strong> API keys</strong>.
            </li>
          </ul>
          <img src={StripeNavigateApiKeys} alt="Stripe screenshot" />
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-bold">
            Step 3: Retrieve Your Publishable Key
          </h3>
          <p className="text-sm">
            In the Standard Keys section, you will find your Publishable Key.
          </p>
          <img src={StripeRetrieveYourPublishableKey} alt="Stripe screenshot" />
          <p className="text-sm">
            Copy this key, as you will need to input it into our platform.
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-bold">
            Step 4: Create a Restricted API Key
          </h3>
          <p className="text-sm">
            In the Restricted Keys section, click on Create Restricted Key.
          </p>
          <img src={StripeCreateRestrictedApiKey} alt="Stripe screenshot" />
          <p className="text-sm">
            Search for the following resource types and set the permissions as
            indicated:
          </p>
          <ul className="list-disc pl-8 text-sm">
            <li>
              <strong>Customers</strong>: Set to <strong>WRITE </strong>
              permissions.
            </li>
            <li>
              <strong>Customer Sessions</strong>: Set to
              <strong> READ </strong>
              permissions.
            </li>
            <li>
              <strong>PaymentIntents</strong>: Set to
              <strong> WRITE </strong>
              permissions.
            </li>
          </ul>
          <p className="text-sm">All other settings can be left as default.</p>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-bold">Step 5: Create the Key</h3>
          <p className="text-sm">
            Click on <strong>Create Key</strong> at the bottom of the page. This
            newly created key is the Restricted API Key that you will need to
            input into our platform.
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-bold">Verifying Your API Keys</h3>
          <p className="text-sm">
            After you input both the Publishable API Key and the Restricted API
            Key into our platform, we will automatically verify their
            correctness.
          </p>
        </div>
        <p className="text-sm font-bold">
          As part of this verification process, a test customer and a test
          payment intent will be created in your account.
        </p>
        <p className="text-sm">
          <strong>Note:</strong> You have the option to delete these test
          records if you do not wish to keep them.
        </p>
      </div>
    </div>
  </Modal>
);
