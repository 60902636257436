import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { CardInputsContainer, FormStateBottomBar } from "~/common/components";
import Input from "~/common/components/ui/Input";
import { TeamMember } from "~/common/utils";
import { useUpdateTeamMemberDetails } from "~/providers/hooks";
import { useTeamMemberStore } from "~/providers/stores";
import { providerDetailsSchema } from "~/providers/utils";
import { ProfilePictureHandler } from "../profilePicture";

export type ProviderDetailsFormValuesType = z.infer<
  typeof providerDetailsSchema
>;

export const ProviderDetails = () => {
  const { setTeamMember } = useTeamMemberStore();

  const { teamMember } = useTeamMemberStore();
  const {
    name,
    email,
    last_name,
    profile_picture_path,
    preferred_reference,
    signature_name,
  } = { ...teamMember };

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful, errors },
  } = useForm<ProviderDetailsFormValuesType>({
    defaultValues: {
      signature_name,
      preferred_reference,
    },
    resolver: zodResolver(providerDetailsSchema),
  });

  const onSuccess = (data: TeamMember) => {
    setTeamMember(data);
    setTimeout(
      () =>
        reset({
          preferred_reference: data.preferred_reference,
          signature_name: data.signature_name,
        }),
      3000,
    );
  };

  const { updateTeamMemberDataMutation, isPending } =
    useUpdateTeamMemberDetails(onSuccess);

  const onSubmit: SubmitHandler<ProviderDetailsFormValuesType> = (data) => {
    updateTeamMemberDataMutation(data);
  };

  return (
    <form
      className="relative flex size-full flex-col items-center justify-between gap-6 text-gray-70 md:gap-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <CardInputsContainer title="User Details">
        <div className="flex size-full flex-col gap-4">
          <div className="flex items-center gap-3">
            <ProfilePictureHandler src={profile_picture_path} />
            <div className="flex flex-col">
              <p className="font-medium">
                {name} {last_name}
              </p>
              <p className="text-sm leading-5">{email}</p>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <Input
              label="How would you like your name to appear in the signature of system-generated messages from you to patients?"
              placeholder="e.g., Dr. John Smith or John Smith, MD"
              disabled={isDirty && isSubmitSuccessful}
              error={errors.signature_name?.message}
              {...register("signature_name")}
            />
            <Input
              label="How would you like to be referred to in system-generated messages to patients?"
              description="For example, “Please contact Dr. Smith to schedule a 20 minute appointment”"
              placeholder="e.g., Dr. Smith"
              disabled={isDirty && isSubmitSuccessful}
              error={errors.preferred_reference?.message}
              {...register("preferred_reference")}
            />
          </div>
        </div>
      </CardInputsContainer>
      <FormStateBottomBar
        successMessage="Information successfully updated"
        isDirty={isDirty}
        isSubmitSuccessful={isSubmitSuccessful}
        handleCancel={() => reset()}
        isPending={isPending}
      />
    </form>
  );
};
