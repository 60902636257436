import {
  MedicationBasicData,
  MedicationFrequency,
  MedicationStrength,
  PatientMedication,
} from "~/common/types";
import { formatMedicationName, Option } from "~/common/utils";

export const formatMedicationsOptions = (
  medications?: MedicationBasicData[],
): Option[] =>
  medications?.map((medication) => ({
    label: formatMedicationName(medication),
    value: medication.id,
    type: medication.type,
  })) ?? [];

export const formatPatientMedicationsOptions = (
  patientMedications?: PatientMedication[],
): Option[] =>
  patientMedications?.map(({ medication, id }) => ({
    label: formatMedicationName(medication),
    value: id,
  })) ?? [];

export const formatStrengthOptions = (
  strengths?: MedicationStrength[],
): Option[] => {
  const sortedStrengths = strengths?.sort((a, b) => {
    const formComparison = a.form.localeCompare(b.form);
    if (formComparison !== 0) {
      return formComparison;
    }
    return a.amount - b.amount;
  });

  return (
    sortedStrengths?.map(({ id, amount, unit, form }) => ({
      label: `${amount} ${unit} ${form}`,
      value: id,
    })) ?? []
  );
};

export const formatFrequenciesOptions = (
  frequencies?: MedicationFrequency[],
): Option[] =>
  frequencies?.map(({ id, description, abbreviation }) => ({
    value: id,
    label:
      description.charAt(0).toUpperCase() +
      description.slice(1) +
      ` (${abbreviation})`,
  })) ?? [];
