import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { uploadFile } from "~/providers/api";

export const useUploadFile = (handleSuccess: (url: string) => void) => {
  const { mutate: uploadFileMutation, isPending } = useMutation({
    mutationFn: (image: File) => uploadFile(image),
    onError: () =>
      toast.error(
        "There was an issue uploading the image. Please try again later.",
      ),
    onSuccess: ({ url }) => {
      toast.success("Image upload successfully.");
      handleSuccess(url);
    },
  });

  return { uploadFileMutation, isPending };
};
