import React, { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

import AuthenticationLayout from "~/common/components/layouts/AuthenticationLayout";
import FormLayout from "~/common/components/layouts/FormLayout";
import SuccessErrorLayout from "~/common/components/layouts/SuccessErrorLayout";
import Input from "~/common/components/ui/Input";
import { requestEmail } from "../api/authService";
import { ErrorResponse } from "../api/axios";
import { providersWelcomeText } from "../constants/constants";
import { ROUTES } from "../router";
import { emailSchema } from "../utils/ProviderSchemas";

const forgotPasswordDescription =
  "Please enter your registered email address. We will send you a link to reset your password.";

type MailFormValues = z.infer<typeof emailSchema>;

export const ForgotPassword = () => {
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();

  const { mutate: requestEmailMutation, isPending } = useMutation({
    mutationFn: (email: string) => requestEmail(email),
    onError: (error: ErrorResponse) => {
      if (error.response?.status === 429) {
        toast.error(
          "Please wait for a minute before requesting the email resend if you haven't received it yet.",
        );
      } else {
        toast.error("There was an error sending the mail. Try again later!");
      }
    },
    onSuccess: () => {
      setShowEmailSuccess(true);
    },
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<MailFormValues>({
    resolver: zodResolver(emailSchema),
  });

  const onSubmit: SubmitHandler<MailFormValues> = (data) => {
    setErrorMessage(undefined);
    requestEmailMutation(data.email);
  };

  const error = errors.email?.message ?? errorMessage;
  return (
    <AuthenticationLayout hideHeaderMobile welcomeText={providersWelcomeText}>
      {!showEmailSuccess ? (
        <FormLayout
          title="Forgot password?"
          description={forgotPasswordDescription}
          submitLabel="Send Email"
          handleGoBack={() => navigate(-1)}
          handleSubmit={handleSubmit(onSubmit)}
          pendingRequest={isPending}
        >
          <div className="flex flex-col gap-2">
            <Input
              id="email"
              label="Email address"
              {...register("email")}
              error={error}
            />
          </div>
        </FormLayout>
      ) : (
        <SuccessErrorLayout
          success
          title="Email successfully sent!"
          description="If your email is registered, a link to reset your password has been sent. Please check your inbox."
        >
          <Link to={ROUTES.login} className="font-semibold underline">
            Back to log in
          </Link>
        </SuccessErrorLayout>
      )}
    </AuthenticationLayout>
  );
};
