import { useQuery } from "@tanstack/react-query";

import { getPaymentSettings } from "~/providers/api/teamMemberPaymentAndCarePlans";

export const useGetPaymentSettings = () => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPaymentSettings(),
    queryKey: ["getPaymentSettings"],
  });
  return { data, isLoading };
};
