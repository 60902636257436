import { Drawer } from "~/common/components";
import { PatientMedicationDetail } from "~/common/types";
import { MedicationDetails } from "~/providers/components/patientProfile/medications/MedicationDetails";

interface MedicationDetailsDrawerProps {
  medication?: PatientMedicationDetail;
  medicationName: string;
  handleClose: () => void;
}

export const MedicationDetailsDrawer = ({
  handleClose,
  medication,
  medicationName,
}: MedicationDetailsDrawerProps) => (
  <Drawer isOpen={!!medication} onClose={handleClose}>
    <MedicationDetails
      medicationName={medicationName}
      medication={medication}
    />
  </Drawer>
);
