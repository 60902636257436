export const TextBubblesIcon = () => (
  <svg
    width="104"
    height="114"
    viewBox="0 0 104 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M97.5224 37.4498C97.5224 37.4498 82.2309 50.872 99.8381 79.6136C115.475 105.139 80.3281 116.204 65.1825 113.241C45.5625 109.403 34.2977 84.6042 19.2936 92.4618C4.28955 100.319 -10.5745 64.7132 11.2504 51.611C38.3995 35.3126 20.9756 25.8413 23.4139 13.8908C25.164 5.31406 50.0681 -7.94648 63.4963 7.17975C74.7962 19.9083 80.3376 15.3546 85.5023 13.6554C92.9512 11.2046 109.21 23.9524 97.5224 37.4498Z"
      fill="#DEE9FF"
    />
    <path
      d="M54.227 52.5751L49.2172 73.1248C49.0312 73.8869 48.9971 74.6781 49.117 75.4534C49.2369 76.2286 49.5084 76.9726 49.9159 77.6428C50.3234 78.3131 50.859 78.8965 51.4921 79.3597C52.1252 79.823 52.8433 80.1569 53.6055 80.3425L78.5218 86.4168C79.1624 93.4509 84.9836 93.971 87.2803 94.212C87.3029 94.2132 87.3253 94.207 87.344 94.1942C87.3627 94.1814 87.3767 94.1628 87.3837 94.1412C87.3907 94.1197 87.3903 94.0964 87.3827 94.0751C87.3751 94.0537 87.3607 94.0355 87.3416 94.0233C85.6924 93.018 85.2 90.262 85.0901 88.0182L86.9398 88.4691C87.7018 88.6551 88.4931 88.6891 89.2683 88.5692C90.0435 88.4493 90.7875 88.1779 91.4578 87.7703C92.1281 87.3628 92.7115 86.8272 93.1747 86.1942C93.6379 85.5611 93.9719 84.843 94.1574 84.0808L99.1672 63.5311C99.3532 62.7691 99.3873 61.9778 99.2674 61.2026C99.1475 60.4274 98.876 59.6834 98.4685 59.0131C98.061 58.3429 97.5254 57.7594 96.8924 57.2962C96.2593 56.833 95.5412 56.4991 94.779 56.3135L61.4447 48.1868C60.6826 48.0008 59.8914 47.9668 59.1162 48.0867C58.3409 48.2066 57.5969 48.478 56.9267 48.8856C56.2564 49.2931 55.673 49.8287 55.2098 50.4618C54.7466 51.0948 54.4126 51.813 54.227 52.5751Z"
      fill="#A2BDFF"
    />
    <path
      d="M95.5578 62.7128L81.6251 59.3161C81.5334 59.2937 81.4409 59.35 81.4185 59.4417L80.7037 62.3736C80.6813 62.4653 80.7376 62.5579 80.8293 62.5802L94.762 65.9769C94.8537 65.9993 94.9463 65.943 94.9686 65.8513L95.6834 62.9194C95.7058 62.8276 95.6495 62.7351 95.5578 62.7128Z"
      fill="white"
    />
    <path
      d="M93.5681 70.8732L55.8185 61.6701C55.7267 61.6477 55.6342 61.704 55.6118 61.7957L54.8971 64.7276C54.8747 64.8194 54.931 64.9119 55.0227 64.9342L92.7723 74.1373C92.8641 74.1597 92.9566 74.1034 92.979 74.0117L93.6937 71.0798C93.7161 70.9881 93.6598 70.8955 93.5681 70.8732Z"
      fill="#D4E5FF"
    />
    <path
      d="M91.5788 79.0336L53.8292 69.8305C53.7375 69.8082 53.645 69.8644 53.6226 69.9562L52.9078 72.888C52.8855 72.9798 52.9417 73.0723 53.0335 73.0947L90.783 82.2977C90.8747 82.3201 90.9673 82.2639 90.9896 82.1721L91.7044 79.2402C91.7268 79.1485 91.6705 79.056 91.5788 79.0336Z"
      fill="#D4E5FF"
    />
    <path
      d="M68.2554 27.9241L76.4478 54.2324C76.7519 55.208 76.8608 56.2339 76.7683 57.2516C76.6757 58.2693 76.3836 59.2588 75.9085 60.1635C75.4335 61.0682 74.7848 61.8705 73.9996 62.5245C73.2143 63.1784 72.308 63.6712 71.3322 63.9748L39.434 73.9077C39.1759 83.1051 31.65 84.2569 28.6838 84.7576C28.6543 84.7615 28.6244 84.7554 28.5988 84.7404C28.5732 84.7254 28.5534 84.7022 28.5425 84.6746C28.5315 84.647 28.5301 84.6165 28.5385 84.588C28.5469 84.5596 28.5645 84.5347 28.5886 84.5174C30.6506 83.0756 31.0656 79.4523 31.0254 76.5259L28.657 77.2634C27.6815 77.5674 26.6556 77.6763 25.6379 77.5837C24.6203 77.4911 23.6309 77.199 22.7262 76.724C21.8215 76.2489 21.0193 75.6003 20.3653 74.8151C19.7114 74.03 19.2186 73.1237 18.915 72.148L10.7225 45.8397C10.4184 44.8641 10.3095 43.8381 10.4021 42.8204C10.4946 41.8028 10.7867 40.8133 11.2618 39.9086C11.7369 39.0038 12.3855 38.2015 13.1707 37.5476C13.956 36.8936 14.8623 36.4008 15.8381 36.0972L58.5129 22.8085C59.4885 22.5044 60.5145 22.3955 61.5321 22.4881C62.5498 22.5806 63.5393 22.8727 64.444 23.3478C65.3488 23.8229 66.151 24.4715 66.805 25.2567C67.459 26.042 67.9518 26.9483 68.2554 27.9241Z"
      fill="#418DF9"
    />
    <path
      d="M38.1163 37.3465L15.2639 44.4535C15.1737 44.4816 15.1233 44.5774 15.1514 44.6676L16.3497 48.5208C16.3777 48.611 16.4736 48.6614 16.5638 48.6333L39.4162 41.5263C39.5064 41.4983 39.5568 41.4024 39.5287 41.3122L38.3304 37.4591C38.3024 37.3689 38.2065 37.3185 38.1163 37.3465Z"
      fill="white"
    />
    <path
      d="M66.9547 39.7886L18.5285 54.8689C18.4383 54.897 18.388 54.9928 18.4161 55.083L19.6157 58.9353C19.6438 59.0254 19.7396 59.0758 19.8298 59.0477L68.256 43.9674C68.3461 43.9394 68.3965 43.8435 68.3684 43.7533L67.1688 39.9011C67.1407 39.8109 67.0448 39.7606 66.9547 39.7886Z"
      fill="#D4E5FF"
    />
    <path
      d="M70.2034 50.2893L21.7658 65.3531C21.6757 65.3811 21.6253 65.477 21.6533 65.5672L22.8516 69.4203C22.8797 69.5105 22.9755 69.5609 23.0657 69.5329L71.5033 54.4691C71.5935 54.441 71.6438 54.3452 71.6158 54.255L70.4175 50.4018C70.3894 50.3116 70.2936 50.2613 70.2034 50.2893Z"
      fill="#D4E5FF"
    />
  </svg>
);
