import { Patient } from "~/common/utils";
import { api, PaginatedServiceResponse } from "./axios";

export const getPatients = async (params?: URLSearchParams) => {
  const res = await api.get<PaginatedServiceResponse<Patient[]>>(
    "/v1/team-members/patients",
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
    },
  );
  return res.data;
};
