import { TableData } from "~/common/components/table/TableData";

interface MedicationsTableRowProps {
  date: string;
  strength: string;
  take: string;
  frequency: string;
  handleSeeDetails?: () => void;
}

export const MedicationsTableRow = ({
  date,
  strength,
  take,
  frequency,
  handleSeeDetails,
}: MedicationsTableRowProps) => (
  <tr className="border-b border-gray-300 py-4 last:border-none">
    <TableData className="py-4">{date}</TableData>
    <TableData className="py-4">{strength}</TableData>
    <TableData className="py-4">{take}</TableData>
    <TableData className="py-4">{frequency}</TableData>
    <TableData className="py-4">
      <button
        className="font-medium text-primary-600 underline underline-offset-4"
        onClick={handleSeeDetails}
      >
        See details
      </button>
    </TableData>
  </tr>
);
