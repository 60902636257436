import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { UpdateNoteData } from "~/common/utils/types";
import { updateNote } from "~/providers/api/patientProfileService";

export const useUpdateNoteMutation = (patientId: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateNoteMutation, isPending } = useMutation({
    mutationFn: (noteData: UpdateNoteData) => updateNote(noteData, patientId),
    onError: () =>
      toast.error(
        "There was an issue updating the note. Please try again later.",
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getPatient"] }).catch(() => {
        toast.error("Failed to refresh patient data.");
      });
    },
  });

  return { isPending, updateNoteMutation };
};
