import { api } from ".";

export const uploadFile = async (image: File) => {
  const formdata = new FormData();
  formdata.append("file", image);
  const res = await api.post<{ url: string }>(
    "/v1/team-members/files",
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return res.data;
};

export const deleteFile = async () => {
  const res = await api.delete<{ url: string }>("/v1/team-members/files");
  return res.data;
};
