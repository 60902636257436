import { useParams } from "react-router-dom";

import { OrderPanel } from "~/providers/components/patientProfile/OrderPanel";

export const PatientOrders = () => {
  const { patientId } = useParams();

  if (!patientId) {
    return null;
  }

  return <OrderPanel patientId={patientId} />;
};
