import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { UpdateNoteData } from "~/common/utils/types";
import { createScriptComment } from "~/providers/api/commentService";

export interface PatientCommentData {
  noteData: UpdateNoteData;
  patientId: string;
  patientNoteId: string;
}
export const useCreateComment = (scriptId?: string) => {
  const queryClient = useQueryClient();

  const { mutate: createCommentMutation, isPending } = useMutation({
    mutationFn: (text: string) => createScriptComment(text, scriptId),
    onError: () =>
      toast.error(
        "There was an issue sending the comment. Please try again later.",
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["getComments"] }),
  });

  return { createCommentMutation, isPending };
};
