import { ChangePatientStatusUpdate } from "../utils/types/paymentSettingsTypes";
import { api } from "./axios";

export const changePatientStatus = async ({
  patientId,
  status,
}: ChangePatientStatusUpdate) => {
  const { data } = await api.put<void>(
    `/v1/team-members/patients/${patientId}/status`,
    {
      status,
    },
  );

  return data;
};
