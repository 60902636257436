import { Note, SectionHeader } from "~/common/components";
import { NOTE_TYPES } from "~/providers/constants";
import { useGetPatientNote } from "~/providers/hooks";
import { useUpdatePatientNoteMutation } from "~/providers/hooks/queries/useUpdatePatientNoteMutation";
import { CurrentMedications } from "./CurrentMedications";
import { DiscontinuedMedications } from "./DiscontinuedMedications";

interface MedicationsProps {
  patientId: string;
}

export const Medications = ({ patientId }: MedicationsProps) => {
  const { data: noteData } = useGetPatientNote(
    patientId,
    NOTE_TYPES.medication,
  );

  const { updatePatientNoteMutation } = useUpdatePatientNoteMutation(
    patientId,
    noteData?.id ?? "",
  );

  const updateNote = async (note: string | null) => {
    await updatePatientNoteMutation({ content: note });
  };

  return (
    <div className="flex w-full flex-col gap-6 overflow-y-auto px-14 py-8">
      <Note content={noteData?.note?.content} handleSubmitNote={updateNote} />
      <CurrentMedications patientId={patientId} />
      <div className="flex w-full flex-col gap-4">
        <SectionHeader>Past Medications</SectionHeader>
        <DiscontinuedMedications patientId={patientId} />
      </div>
    </div>
  );
};
