import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { changePatientStatus } from "~/providers/api/changePatientStatus";

export const useChangePatientStatus = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: changePatientStatusMutation, isPending } = useMutation({
    mutationFn: changePatientStatus,
    onError: () =>
      toast.error(
        "There was an issue referring the patient. Please try again later.",
      ),
    onSuccess: async (_, { patientId }) => {
      await queryClient.invalidateQueries({
        queryKey: ["getPatient", patientId],
      });
      onSuccess();
    },
  });

  return { changePatientStatusMutation, isPending };
};
