import { AnswersRenderer, ArrowIcon, Typography } from "~/common/components";
import { DIRECTIONS } from "~/common/constants";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";
import {
  formatDate,
  getIconByCode,
  isBundleFeedContext,
} from "~/providers/utils";
import { PatientFeed } from "~/providers/utils/types/interactions";

interface CompletedCheckInActivityFeedPCard {
  patientActivity: PatientFeed;
  handleViewCheckIn: () => void;
}

export const CompletedCheckInActivityCard = ({
  patientActivity,
  handleViewCheckIn,
}: CompletedCheckInActivityFeedPCard) => {
  if (isBundleFeedContext(patientActivity.context)) return null;
  const formattedDate = formatDate(patientActivity.created_at);

  const { checkin_code, creation_type, data } = { ...patientActivity.context };
  return (
    <div className="flex flex-col rounded-md border border-neutral-80 px-4">
      <div className="border-neutral-70 flex flex-row items-center justify-between border-b py-2.5">
        <div className="flex flex-row items-center gap-2">
          {getIconByCode(checkin_code, creation_type)()}
          <Typography>{patientActivity.description}</Typography>
        </div>
        <Typography variant={TYPOGRAPHY_VARIANTS.light}>
          {formattedDate}
        </Typography>
      </div>
      <div className="flex w-full items-end justify-between py-4 pl-10">
        <AnswersRenderer
          answers={data}
          checkinCode={patientActivity.context.checkin_code}
          className="w-2/3"
          isPreview
        />
        <button
          className="flex items-center justify-end gap-2 text-primary-600"
          onClick={handleViewCheckIn}
        >
          See more
          <ArrowIcon direction={DIRECTIONS.right} />
        </button>
      </div>
    </div>
  );
};
