import { useQuery } from "@tanstack/react-query";

import { InteractionCreationTypes } from "~/common/utils/types/checkInTypes";
import { getPendingPatientInteractions } from "~/providers/api/patientProfileService";

export const useGetPendingPatientInteractions = (
  patientId: string,
  interactionsType: InteractionCreationTypes[],
  unique_by_bundle = false,
) => {
  const { data, isLoading } = useQuery({
    queryFn: () =>
      getPendingPatientInteractions(
        patientId,
        interactionsType,
        unique_by_bundle,
      ),
    queryKey: [
      "getPendingPatientInteractions",
      patientId,
      interactionsType,
      unique_by_bundle,
    ],
  });
  return { data, isLoading };
};
