import { ORDER, ORDER_BY } from "~/common/constants";
import {
  CreatePatientMedicationData,
  DiscontinuePatientMedicationData,
  GetPatientMedicationsRequest,
  MedicationBasicData,
  MedicationFrequency,
  ModifyPatientMedicationData,
  PatientMedication,
} from "~/common/types";
import { UpdateNoteData } from "~/common/utils/types";
import { NoteType } from "../constants";
import { PatientNoteData, PauseScriptFormValuesType } from "../utils";
import { api, PaginatedServiceResponse } from "./axios";

export const getMedicationsList = async () => {
  const { data } = await api.get<MedicationBasicData[]>(
    `/v1/team-members/medications`,
  );
  return { data };
};

export const getMedicationsFrequencies = async () => {
  const { data } = await api.get<MedicationFrequency[]>(
    `/v1/team-members/frequencies`,
  );
  return { data };
};

export const createMedication = async ({
  patientId,
  medicationData,
}: CreatePatientMedicationData) => {
  const { data } = await api.post<string>(
    `/v1/team-members/patients/${patientId}/medications`,
    { ...medicationData },
  );
  return { data };
};

export const getPatientMedications = async ({
  patientId,
  status,
}: GetPatientMedicationsRequest) => {
  const { data } = await api.get<PaginatedServiceResponse<PatientMedication[]>>(
    `/v1/team-members/patients/${patientId}/medications`,
    {
      params: {
        page_size: 100,
        page: 1,
        status,
        order_by: ORDER_BY.genericName,
        order: ORDER.ascendant,
      },
    },
  );
  return data;
};

export const discontinuePatientMedication = async (
  patientId: string,
  patient_medication_id: string,
  discontinueData: DiscontinuePatientMedicationData,
) => {
  const { data } = await api.post<PatientMedication>(
    `/v1/team-members/patients/${patientId}/medications/${patient_medication_id}/discontinue`,
    discontinueData,
  );
  return data;
};

export const modifyPatientMedication = async ({
  patientId,
  patientMedicationId,
  medicationDetails,
}: ModifyPatientMedicationData) => {
  const { data } = await api.post<PatientMedication>(
    `/v1/team-members/patients/${patientId}/medications/${patientMedicationId}/details`,
    medicationDetails,
  );
  return { data };
};

export const updatePatientNote = async (
  note: UpdateNoteData | null,
  patient_id: string,
  patient_note_id: string,
) => {
  const { data } = await api.patch<PatientNoteData>(
    `/v1/team-members/patients/${patient_id}/notes/${patient_note_id}`,
    note,
  );
  return { data };
};

export const getPatientNote = async (
  patient_id: string,
  note_type: NoteType,
) => {
  const { data } = await api.get<PatientNoteData>(
    `/v1/team-members/patients/${patient_id}/notes/${note_type}`,
  );
  return data;
};

export const pausePatientMedicationScript = async (
  patient_id: string,
  scriptsData: PauseScriptFormValuesType,
) => {
  const { data } = await api.post<string>(
    `/v1/team-members/patients/${patient_id}/medications/scripts-status`,
    scriptsData,
  );

  return data;
};
