import { PatientMedicationDetail } from "~/common/types";
import { DetailsRowProps } from "~/providers/components/patientProfile/medications";
import { formatDate } from "../orders";
import { generateDailyDoseValue } from "./generateDailyDoseValue";
import { generateFrequency } from "./generateFrequency";
import { generatePrescribedDaysValue } from "./generatePrescribedDaysValue";
import { generateStrength } from "./generateStrength";
import { getMaxTakeValue } from "./getMaxTakeValue";

export const getMedicationDetailsProps = (
  medication: PatientMedicationDetail,
): DetailsRowProps[] => [
  { label: "Start date", detail: formatDate(medication.start_date) },
  {
    label: "Strength",
    detail: generateStrength(medication.medication_strength),
  },
  { label: "Take", detail: medication.take },
  { label: "Frequency", detail: generateFrequency(medication) },
  { label: "Quantity prescribed", detail: medication.prescribed_quantity },
  { label: "Number of refills", detail: medication.number_of_refills },
  {
    label: "Prescribed days",
    detail: generatePrescribedDaysValue({
      quantity: medication.prescribed_quantity,
      maxTake: getMaxTakeValue(medication.take),
      frequency: medication.frequency.per_day,
    }),
  },
  {
    label: "Daily dose",
    detail: generateDailyDoseValue({
      strengthAmount: medication.medication_strength.amount,
      maxTake: getMaxTakeValue(medication.take),
      frequency: medication.frequency.per_day,
    }),
  },
  {
    label: "Internal note",
    detail: medication.internal_note,
    className: "italic",
  },
  {
    label: "Message to patient",
    detail: medication.message_to_patient,
    className: "italic",
  },
];
