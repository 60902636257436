import { PropsWithChildren } from "react";

import { TopBar } from "~/common/components";
import { useUiStore } from "../stores";

export const RequestsLayout = ({ children }: PropsWithChildren) => {
  const { setSideBarOpen } = useUiStore();

  const handleOpenSideBar = () => void setSideBarOpen(true);

  return (
    <div className="flex h-screen-calculated flex-col overflow-auto px-14 xl:py-8">
      <TopBar handleOpenSideBar={handleOpenSideBar} className="px-0 py-6" />
      <div className="flex flex-col gap-4">{children}</div>
    </div>
  );
};
