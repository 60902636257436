import React from "react";
import { ArrowUpRightIcon } from "@heroicons/react/20/solid";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";

import { Drawer, LoadingState, SectionHeader } from "~/common/components";
import { CloseButton } from "~/common/components/ui/CloseButton";
import { useDeleteQueryParams } from "~/common/hooks/useDeleteQueryParams";
import { MedicationDetails } from "~/providers/components/patientProfile/medications/MedicationDetails";
import { useGetScriptDetails } from "~/providers/hooks/queries/useGetScriptDetailsForProvider";
import { ROUTES } from "~/providers/router";
import { CommentSection } from "./commentSection/CommentSection";
import { ScriptDetails } from "./detailsSection/ScriptDetails";
import { ScriptRequestDetails } from "./detailsSection/ScriptRequestDetails";

export const ScriptDetailsDrawer = () => {
  const { deleteQueryParams } = useDeleteQueryParams();

  const [params] = useSearchParams();
  const id = params.get("id") ?? undefined;

  const navigate = useNavigate();

  const { data: scriptRequest, isLoading } = useGetScriptDetails(id);
  const { name, last_name } = { ...scriptRequest?.patient };

  const handleClose = () => deleteQueryParams(["id"]);
  const handleNavigate = () =>
    navigate(
      generatePath(ROUTES.medication, {
        patientId: scriptRequest?.patient.id ?? "",
      }),
    );

  return (
    <Drawer
      isOpen={!!id}
      childrenContainerClassName="gap-0 p-0 md:w-drawer-prescriptions"
      containerClassName="md:w-drawer-prescriptions"
      onClose={handleClose}
      showCloseButton={false}
    >
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          <SectionHeader className="flex h-[11%] flex-col gap-1 px-5 py-4">
            <div className="flex items-center justify-between">
              <button
                onClick={handleNavigate}
                className="flex items-center gap-1 whitespace-nowrap text-base font-semibold text-primary-600"
              >
                Go to Medications
                <ArrowUpRightIcon className="size-5 stroke-2" />
              </button>
              <CloseButton onClose={handleClose} />
            </div>
            {`${name} ${last_name} request`}
          </SectionHeader>
          <div className="grid h-[89%] w-full grid-cols-2  grid-rows-1 border-t border-neutral-700">
            <div className="flex flex-col">
              <ScriptDetails
                id={id}
                scriptRequest={scriptRequest}
                handleCancel={handleClose}
              />
              <CommentSection id={id} />
            </div>
            <div className="overflow-y-auto border-l px-6 py-4">
              {scriptRequest && <ScriptRequestDetails {...scriptRequest} />}

              <div className="border-t" />

              <MedicationDetails
                medicationName={
                  scriptRequest?.patient_medication.medication.generic_name
                }
                medication={
                  scriptRequest?.patient_medication
                    .patient_medications_details[0]
                }
                className="border-none py-2"
              />
            </div>
          </div>
        </>
      )}
    </Drawer>
  );
};
