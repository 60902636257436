import { useQuery } from "@tanstack/react-query";

import { getPatientSchedules } from "~/providers/api/patientProfileService";

export const useGetPatientSchedules = (patientId: string) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientSchedules(patientId),
    queryKey: ["getPatientSchedules", patientId],
  });
  return { data, isLoading };
};
