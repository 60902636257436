import { PATIENT_SCRIPT_STATUS } from "~/common/constants";
import { PatientScriptsStatusTypes } from "~/common/types/scriptsTypes";

export const getScriptStatusOptions = (status: PatientScriptsStatusTypes) =>
  ({
    [PATIENT_SCRIPT_STATUS.canceled]: [
      { value: PATIENT_SCRIPT_STATUS.canceled, label: "Canceled" },
      { value: PATIENT_SCRIPT_STATUS.pending, label: "Pending" },
    ],
    [PATIENT_SCRIPT_STATUS.pending]: [
      {
        value: PATIENT_SCRIPT_STATUS.sent_to_pharmacy,
        label: "Sent to pharmacy",
      },
      { value: PATIENT_SCRIPT_STATUS.canceled, label: "Canceled" },
      { value: PATIENT_SCRIPT_STATUS.pending, label: "Pending" },
    ],
    [PATIENT_SCRIPT_STATUS.sent_to_pharmacy]: [
      {
        value: PATIENT_SCRIPT_STATUS.sent_to_pharmacy,
        label: "Sent to pharmacy",
      },
      { value: PATIENT_SCRIPT_STATUS.pending, label: "Pending" },
    ],
  })[status];
