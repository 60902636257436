import { useQuery } from "@tanstack/react-query";

import { getMedicationsList } from "~/providers/api/patientMedicationsService";

export const useGetMedicationLists = (enabled: boolean) => {
  const { data, isLoading } = useQuery({
    queryFn: getMedicationsList,
    queryKey: ["getMedicationsList"],
    enabled,
  });
  return { data, isLoading };
};
