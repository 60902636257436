import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormResetField,
  UseFormWatch,
} from "react-hook-form";

import {
  BasicCheckIn,
  CHECK_IN_CODES,
  CHECK_IN_TYPES,
  HealthFactorsCodes,
} from "~/common/utils";
import { AddOrderFormValues } from "./AddOrderForm";
import { MedChangeFormInputs } from "./MedChangeFormInputs";
import { RecurringOrderFormInputs } from "./RecurringOrderFormInputs";
import { ScheduleAppointmentInputs } from "./ScheduleAppointmentInputs";

interface InputsRenderProps {
  selectedOrder: BasicCheckIn;
  register: UseFormRegister<AddOrderFormValues>;
  errors?: FieldErrors<AddOrderFormValues>;
  reset: UseFormReset<AddOrderFormValues>;
  resetField: UseFormResetField<AddOrderFormValues>;
  control: Control<AddOrderFormValues>;
  getValues: UseFormGetValues<AddOrderFormValues>;
  watch: UseFormWatch<AddOrderFormValues>;
}

export const InputsRenderer = ({
  selectedOrder,
  register,
  control,
  errors,
  ...props
}: InputsRenderProps) => {
  const isBundle = selectedOrder.type === CHECK_IN_TYPES.bundles;
  const isScheduleAppointment =
    selectedOrder.code === CHECK_IN_CODES.schedule_appointment &&
    selectedOrder.type === CHECK_IN_TYPES.checkins;

  if (isBundle)
    return (
      <MedChangeFormInputs
        register={register}
        errors={errors}
        control={control}
      />
    );

  if (isScheduleAppointment)
    return (
      <ScheduleAppointmentInputs
        register={register}
        errors={errors}
        control={control}
      />
    );

  return (
    <RecurringOrderFormInputs
      register={register}
      errors={errors}
      selectedOrderCode={selectedOrder.code as HealthFactorsCodes}
      control={control}
      {...props}
    />
  );
};
