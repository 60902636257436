import React, { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import MenuIcon from "~/common/components/icons/MenuIcon";
import { SectionHeader } from "./typography";

interface TopBarProps
  extends PropsWithChildren,
    ComponentPropsWithoutRef<"div"> {
  handleOpenSideBar: () => void;
  title?: string;
  className?: string;
}

export const TopBar = ({
  title,
  children,
  className,
  handleOpenSideBar,
  ...props
}: TopBarProps) => (
  <div
    className={twMerge(
      "sticky top-0 z-10 flex justify-between whitespace-nowrap bg-white py-4 xl:hidden",
      className,
    )}
    {...props}
  >
    <div className="flex w-1/4 items-center gap-4">
      <button className="w-fit lg:hidden" onClick={handleOpenSideBar}>
        <MenuIcon />
      </button>
      {title && (
        <SectionHeader className="text-xl font-semibold lg:text-3xl">
          {title}
        </SectionHeader>
      )}
    </div>
    {children}
  </div>
);
