import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { modifyPatientMedication } from "~/providers/api/patientMedicationsService";

interface UseModifyMedicationProps {
  onSuccess: () => void;
}

export const useModifyMedication = ({
  onSuccess,
}: UseModifyMedicationProps) => {
  const queryClient = useQueryClient();
  const { mutate: modifyMedicationMutation, isPending } = useMutation({
    mutationFn: modifyPatientMedication,
    onError: () =>
      toast.error(
        "We had trouble adding the patient's medication. Try again later!",
      ),
    onSuccess: async () => {
      onSuccess();
      await queryClient.invalidateQueries({
        queryKey: ["getPatientMedications"],
      });
    },
  });

  return { modifyMedicationMutation, isPending };
};
