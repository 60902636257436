import { useQuery } from "@tanstack/react-query";

import { PatientMedicationStatus } from "~/common/types";
import { getPatientMedications } from "~/providers/api/patientMedicationsService";

export const useGetPatientMedications = (
  status: PatientMedicationStatus,
  patientId: string,
  enabled?: boolean,
) => {
  const { data, isLoading, isFetched } = useQuery({
    queryFn: () => getPatientMedications({ patientId, status }),
    queryKey: ["getPatientMedications", status, patientId],
    enabled,
  });
  return { data, isLoading, isFetched };
};
