import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils/types";

export const PlusOneIcon = ({ className }: IconProps) => (
  <svg
    className={twMerge("h-5 w-5 text-primary-600", className)}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.15874 5.74558L6.15874 14.7583M16.6818 14.7583V5.16406L14.2816 5.74558M10.6651 10.252L1.65234 10.252"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
