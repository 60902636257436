import { useParams } from "react-router-dom";

import { ActivityFeed } from "~/providers/components/patientProfile/ActivityFeed";

export const PatientActivityFeed = () => {
  const { patientId } = useParams();

  if (!patientId) {
    return null;
  }

  return <ActivityFeed patientId={patientId} />;
};
