import { Control, FieldErrors, UseFormRegister } from "react-hook-form";
import { useParams } from "react-router-dom";

import DatePicker from "~/common/components/ui/DatePicker";
import Select from "~/common/components/ui/select/Select";
import { PATIENT_MEDICATION_STATUS } from "~/common/constants";
import { getTodayDate } from "~/common/utils/getTodayDate";
import { useGetPatientMedications } from "~/providers/hooks";
import { formatPatientMedicationsOptions } from "~/providers/utils";
import { AddOrderFormValues } from "./AddOrderForm";

interface MedChangeFormInputsProps {
  register: UseFormRegister<AddOrderFormValues>;
  control: Control<AddOrderFormValues>;
  errors?: FieldErrors<AddOrderFormValues>;
}

export const MedChangeFormInputs = ({
  register,
  control,
  errors,
}: MedChangeFormInputsProps) => {
  const today = getTodayDate();
  const { patientId } = useParams();

  const { data: currentMedications } = useGetPatientMedications(
    PATIENT_MEDICATION_STATUS.active,
    patientId ?? "",
    !!patientId,
  );

  return (
    <div className="flex w-full flex-col items-start gap-3">
      {currentMedications && (
        <Select
          containerClassName="gap-2"
          id="patientMedicationId"
          label="Medication"
          placeholder="Select medication"
          control={control}
          options={formatPatientMedicationsOptions(currentMedications.data)}
          error={
            errors && "patientMedicationId" in errors
              ? errors?.patientMedicationId?.message
              : undefined
          }
        />
      )}
      <DatePicker
        label="Medication start date"
        min={today}
        id="startDate"
        {...register("startDate")}
        error={
          errors && "startDate" in errors
            ? errors?.startDate?.message
            : undefined
        }
      />
    </div>
  );
};
