import { bundlesData } from "~/common/constants/bundles";
import { checkInBasicData } from "~/providers/constants";
import { isBundleCode } from "~/providers/utils/orders/checkIsCode";
import { OrderCodes } from "~/providers/utils/types/orderTypeValues";
import { OrderDescriptionLayout } from "./OrderDescriptionLayout";

interface RenderDescriptionProps {
  code: OrderCodes;
}

export const RenderDescription = ({ code }: RenderDescriptionProps) =>
  isBundleCode(code) ? (
    bundlesData[code].basicData.map((basicData, index) => (
      <OrderDescriptionLayout {...basicData} key={index} />
    ))
  ) : (
    <OrderDescriptionLayout {...checkInBasicData[code]} />
  );
