import { useQuery } from "@tanstack/react-query";

import { getPatient } from "~/providers/api/patientProfileService";

export const useGetPatient = (patientId?: string) => {
  const { data } = useQuery({
    queryFn: () => getPatient(patientId ?? ""),
    queryKey: ["getPatient", patientId],
    enabled: !!patientId,
  });
  return { data };
};
