import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { Button, InformationIcon } from "~/common/components";
import Input from "~/common/components/ui/Input";
import { useUpdatePaymentSettings } from "~/providers/hooks/queries/teamMemberPaymentAndCarePlans/useUpdatePaymentSettings";
import { paymentSettingsSchema } from "~/providers/utils/paymentAndCarePlansSchemas";
import { ProviderPaymentSettings } from "~/providers/utils/types/paymentSettingsTypes";
import { StripeInfoModal } from "../../StripeInfoModal";

export type PaymentSettingsFormValuesType = z.infer<
  typeof paymentSettingsSchema
>;

interface StripeIntegrationFormProps {
  providerSettings?: ProviderPaymentSettings;
  isLoadingProviderSettings: boolean;
}

export const StripeIntegrationForm = ({
  providerSettings,
  isLoadingProviderSettings,
}: StripeIntegrationFormProps) => {
  const { updatePaymentSettingsMutation, isPending, validationErrorDetail } =
    useUpdatePaymentSettings();

  const [showInstructionsModal, setShowInstructionsModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<PaymentSettingsFormValuesType>({
    resolver: zodResolver(paymentSettingsSchema),
    values: {
      secret_key: providerSettings?.secret_key ?? "",
      publishable_key: providerSettings?.publishable_key ?? "",
    },
  });

  const onSubmit: SubmitHandler<PaymentSettingsFormValuesType> = (data) => {
    updatePaymentSettingsMutation({
      updatePaymentSettingsData: data,
      providerPaymentSettingsId: providerSettings?.id ?? "",
    });
  };

  return (
    <>
      <StripeInfoModal
        show={showInstructionsModal}
        onClose={() => setShowInstructionsModal(false)}
      />
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <span className="text-lg font-medium">Stripe integration</span>
          <button onClick={() => setShowInstructionsModal(true)}>
            <InformationIcon />
          </button>
        </div>
        <p>
          To receive payments for consultation fees, you must set up a Stripe
          account. Enter your API keys below to activate the integration.
        </p>
      </div>
      <form
        className="flex size-full flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        {isLoadingProviderSettings ? (
          <Skeleton width={200} height={16} />
        ) : (
          <>
            <div className="flex gap-3">
              <Input
                label="Stripe publishable key"
                placeholder="Enter publishable key"
                error={errors.publishable_key?.message}
                isError={Boolean(validationErrorDetail)}
                {...register("publishable_key")}
              />
              <Input
                label="Stripe restricted API key"
                placeholder="Enter API key"
                error={errors.secret_key?.message}
                isError={Boolean(validationErrorDetail)}
                {...register("secret_key")}
                type="password"
              />
              <Button
                disabled={isPending || !isDirty}
                type="submit"
                size="sm"
                isLoading={isPending}
                className={twMerge(
                  "h-11 w-72 self-end",
                  (Boolean(errors.publishable_key) ||
                    Boolean(errors.secret_key)) &&
                    "mt-1 self-center",
                )}
              >
                Save changes
              </Button>
            </div>
            {validationErrorDetail && (
              <p className="text-sm text-error">{validationErrorDetail}</p>
            )}
          </>
        )}
      </form>
    </>
  );
};
