import { BundleIcon, RepeatIcon } from "~/common/components/icons";
import { PlusOneIcon } from "~/common/components/icons/PlusOneIcon";
import { INTERACTION_CREATION_TYPES } from "~/common/utils/types/checkInTypes";

export const orderTypeIcon = {
  [INTERACTION_CREATION_TYPES.bundle]: <BundleIcon className="text-gray-70" />,
  [INTERACTION_CREATION_TYPES.schedule]: (
    <RepeatIcon className="h-4 w-4 text-gray-70" />
  ),
  [INTERACTION_CREATION_TYPES.special_request]: (
    <PlusOneIcon className=" text-gray-70" />
  ),
  [INTERACTION_CREATION_TYPES.patient_created]: (
    <PlusOneIcon className=" text-gray-70" />
  ),
} as const;
