import { FieldErrors, UseFormRegister, UseFormWatch } from "react-hook-form";

import { ErrorText } from "~/common/components";
import Input from "~/common/components/ui/Input";
import CheckBox from "~/patients/components/ui/CheckBox";
import { UpsertMedicationFormValues } from "../UpsertMedicationModal";

interface TakeInputsProps {
  register: UseFormRegister<UpsertMedicationFormValues>;
  watch: UseFormWatch<UpsertMedicationFormValues>;
  errors: FieldErrors<UpsertMedicationFormValues>;
}

export const TakeInputs = ({ errors, register, watch }: TakeInputsProps) => {
  return (
    <div className="flex w-1/2 flex-col gap-2">
      <div className="flex justify-between">
        <label
          className="flex w-full justify-between text-sm font-medium"
          htmlFor="take"
        >
          Take
        </label>
        <div className="flex">
          <CheckBox
            labelClassName="text-sm inline "
            className="h-4 w-4"
            id="range"
            {...register("patient_medications_detail.range")}
          >
            Range
          </CheckBox>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Input
          id="take"
          placeholder=""
          {...register("patient_medications_detail.take")}
          isError={!!errors.patient_medications_detail?.take?.message}
        />
        <p>to</p>
        <Input
          placeholder=""
          id="take_max"
          disabled={!watch("patient_medications_detail.range")}
          isError={!!errors.patient_medications_detail?.take_max?.message}
          {...register("patient_medications_detail.take_max")}
        />
      </div>

      {errors.patient_medications_detail?.take && (
        <ErrorText>
          {errors.patient_medications_detail?.take?.message}
        </ErrorText>
      )}
      {errors.patient_medications_detail?.take_max && (
        <ErrorText>
          {errors.patient_medications_detail?.take_max?.message}
        </ErrorText>
      )}
    </div>
  );
};
