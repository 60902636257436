import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

import { EditIcon } from "~/common/components/icons";
import { useTeamMemberStore } from "~/providers/stores/useTeamMemberStore";
import { NoteLayout } from "./NoteLayout";

interface DisplayNoteProps {
  content?: string;
  handleEdit: () => void;
}
export const DisplayNote = ({ content, handleEdit }: DisplayNoteProps) => {
  const { isStaff } = useTeamMemberStore();

  const [showFullNote, setShowFullNote] = useState(false);
  const [shouldHideContent, setShouldHideContent] = useState(false);
  const toggleShowFullNote = () => setShowFullNote((prev) => !prev);
  const noteContent = content && content.length > 0 ? content : "Add note here";

  const contentRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const { scrollHeight, clientHeight } = contentRef.current;
      setShouldHideContent(scrollHeight > clientHeight);
    }
  }, [content]);

  return (
    <NoteLayout>
      <div className="flex flex-row justify-between">
        <h2 className="text-lg font-semibold text-gray-800">Note</h2>
        {!isStaff && (
          <button className="h-fit w-fit" onClick={handleEdit}>
            <EditIcon />
          </button>
        )}
      </div>
      <div
        className={twMerge(
          showFullNote
            ? "line-clamp-none max-h-full overflow-auto"
            : "line-clamp-3 max-h-20",
        )}
      >
        <p
          className={twMerge(
            "whitespace-pre-wrap leading-5",
            !content && "italic tracking-wide text-gray-60",
          )}
          ref={contentRef}
        >
          {noteContent}
        </p>
      </div>
      {shouldHideContent && (
        <button
          onClick={toggleShowFullNote}
          className="w-fit leading-5 text-primary-600 hover:underline"
        >
          {showFullNote ? "View less" : "View more"}
        </button>
      )}
    </NoteLayout>
  );
};
