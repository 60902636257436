import { useQuery } from "@tanstack/react-query";

import { getPatientNote } from "~/providers/api/patientMedicationsService";
import { NoteType } from "~/providers/constants";

export const useGetPatientNote = (patientId: string, noteType: NoteType) => {
  const { data, isLoading, isFetched } = useQuery({
    queryFn: () => getPatientNote(patientId, noteType),
    queryKey: ["getPatientNote", patientId, noteType],
  });
  return { data, isLoading, isFetched };
};
