import React from "react";
import { twMerge } from "tailwind-merge";

interface NotificationPointProps {
  className?: string;
}

export const NotificationPoint = ({ className }: NotificationPointProps) => {
  return (
    <svg
      className={twMerge("h-2.5 w-2.5", className)}
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="4.5" r="4.5" fill="currentColor" />
    </svg>
  );
};
