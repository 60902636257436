import { useEffect } from "react";
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormResetField,
  UseFormWatch,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { CancelSubmitButtons } from "~/common/components/ui/CancelSubmitButtons";
import Select from "~/common/components/ui/select/Select";
import { scrollToBottom } from "~/common/utils/scrollToBottom";
import { BasicCheckIn } from "~/common/utils/types/checkInTypes";
import {
  addOrderSchema,
  bundleFormSchema,
  checkInFormSchema,
  scheduleAppointmentFormSchema,
  scheduleFormSchema,
} from "~/providers/utils/addOrderSchema";
import { getOrderByCode, getOrderTypesOptions } from "~/providers/utils/orders";
import { OrderCodes } from "~/providers/utils/types/orderTypeValues";
import { InputsRenderer } from "./InputsRenderer";
import { NoteInput } from "./NoteInput";
import { RenderDescription } from "./RenderDescription";
import { RenderWarning } from "./RenderWarning";

export type AddOrderFormValues = z.infer<typeof addOrderSchema>;
export type BundleFormValues = z.infer<typeof bundleFormSchema>;
export type ScheduleFormValues = z.infer<typeof scheduleFormSchema>;
export type CheckInFormValues = z.infer<typeof checkInFormSchema>;
export type ScheduleAppointmentFormValues = z.infer<
  typeof scheduleAppointmentFormSchema
>;
interface AddOrderFormProps {
  patientId: string;
  watch: UseFormWatch<AddOrderFormValues>;
  reset: UseFormReset<AddOrderFormValues>;
  resetField: UseFormResetField<AddOrderFormValues>;
  control: Control<AddOrderFormValues>;
  register: UseFormRegister<AddOrderFormValues>;
  handleCancel: () => void;
  handleSubmit: () => void;
  errors?: FieldErrors<AddOrderFormValues>;
  checkInsList: BasicCheckIn[];
  getValues: UseFormGetValues<AddOrderFormValues>;
  isPending: boolean;
}

export const AddOrderForm = ({
  watch,
  reset,
  control,
  register,
  handleCancel,
  handleSubmit,
  resetField,
  checkInsList,
  isPending,
  errors,
  getValues,
  patientId,
}: AddOrderFormProps) => {
  const selectedOrderCode = watch("orderType");
  const selectedOrder = getOrderByCode(selectedOrderCode, checkInsList);

  const repeatOrderSelected = watch("recurringOrder");

  useEffect(() => {
    repeatOrderSelected && scrollToBottom("bottom");
  }, [repeatOrderSelected]);

  return (
    <form className="flex  w-full flex-col items-end gap-4 text-gray-70">
      <div
        className={twMerge(
          "flex max-h-order-modals w-full flex-col gap-3",
          selectedOrderCode && "overflow-y-auto p-2",
        )}
      >
        <div className="flex flex-col gap-2">
          <Select
            containerClassName="gap-2"
            id="orderType"
            label="Order type"
            placeholder="Select order type"
            control={control}
            options={getOrderTypesOptions(checkInsList)}
            onChange={(e) =>
              reset({
                orderType: e as OrderCodes | undefined,
              })
            }
          />
          {selectedOrderCode && <RenderDescription code={selectedOrderCode} />}
        </div>
        {selectedOrder && (
          <div className="flex w-full flex-col items-end gap-3.5 transition-all">
            <InputsRenderer
              selectedOrder={selectedOrder}
              register={register}
              errors={errors}
              control={control}
              reset={reset}
              resetField={resetField}
              getValues={getValues}
              watch={watch}
            />
            <div id="bottom" />

            <div className="flex w-full flex-col gap-3">
              <NoteInput
                id="patientNote"
                register={register("patientNote")}
                description="Message to patient (optional)"
                placeholder="Add a message for the patient..."
                error={errors?.patientNote?.message}
              />
              <NoteInput
                id="internalNote"
                register={register("internalNote")}
                description="Internal note (optional)"
                placeholder="Add an internal note..."
                error={errors?.internalNote?.message}
              />
            </div>
            <RenderWarning
              patientId={patientId}
              selectedOrder={selectedOrder}
            />
          </div>
        )}
      </div>
      {selectedOrder && (
        <CancelSubmitButtons
          variant="right-outline-black"
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isSubmitting={isPending}
          submitButtonLabel="Save"
        />
      )}
    </form>
  );
};
