import { useParams } from "react-router-dom";

import { INTERACTION_CREATION_TYPES } from "~/common/utils/types/index";
import { useGetPatientCheckinRelatedData } from "~/providers/hooks/queries/useGetPatientCheckinRelatedData";
import { formatDate } from "~/providers/utils/orders/getDateText";
import { Order } from "~/providers/utils/types/interactions";

interface BundleCheckinsDataProps {
  order: Order;
}
export const BundleCheckinsData = ({ order }: BundleCheckinsDataProps) => {
  const { patientId } = useParams();

  const { data: patientCheckinRelatedData } = useGetPatientCheckinRelatedData(
    order.id,
    patientId,
    order.type === INTERACTION_CREATION_TYPES.bundle,
  );

  return (
    <div className="flex flex-col gap-4 pt-6">
      {patientCheckinRelatedData?.interactions.map((checkin) => (
        <div key={checkin.id} className="flex flex-row gap-2">
          <p className="font-light text-gray-10">{checkin.description}</p>
          <p>{formatDate(checkin.due_date)}</p>
        </div>
      ))}
    </div>
  );
};
