import React from "react";

import { IconProps } from "~/common/utils";

export const ReferIcon = ({ className, ...props }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M12 16V9M9 11L11.2929 8.70711C11.6834 8.31658 12.3166 8.31658 12.7071 8.70711L15 11M21 12C21 16.9706 16.9706 21 12 21H3.72413C3.3743 21 3.13262 20.65 3.25656 20.3228L4.15021 17.9642C4.37943 17.3593 4.28826 16.6875 3.99225 16.1122C3.35801 14.8796 3 13.4816 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
