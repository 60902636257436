import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { DiscontinuePatientMedicationData } from "~/common/types";
import { discontinuePatientMedication } from "~/providers/api/patientMedicationsService";

export const useDiscontinuePatientMedication = (
  patientId: string,
  patient_medication_id: string,
  handleSuccess?: () => void,
) => {
  const queryClient = useQueryClient();

  const { mutate: discontinuePatientMedicationMutation, isPending } =
    useMutation({
      mutationFn: (data: DiscontinuePatientMedicationData) =>
        discontinuePatientMedication(patientId, patient_medication_id, data),
      onError: () =>
        toast.error(
          "There was an issue discontinuing the medication. Please try again later.",
        ),
      onSuccess: () => {
        handleSuccess;
        queryClient
          .invalidateQueries({ queryKey: ["getPatientMedications"] })
          .catch(() => {
            toast.error("Failed to refresh patient medications.");
          });
      },
    });

  return { isPending, discontinuePatientMedicationMutation };
};
