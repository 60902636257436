import { BundleCodes, bundlesData } from "~/common/constants/bundles";
import {
  BasicCheckIn,
  CHECK_IN_CODES,
  CHECK_IN_TYPES,
  HEAlTH_FACTORS_CODES,
  HealthFactorsCodes,
} from "~/common/utils/types/checkInTypes";
import { checkInBasicData } from "~/providers/constants";

const checkInLabel = (checkIn: BasicCheckIn) => {
  if (checkIn.code === HEAlTH_FACTORS_CODES.status_check)
    return checkInBasicData[checkIn.code].name;
  return (
    `${
      checkIn.code != CHECK_IN_CODES.schedule_appointment
        ? "Health Factor - "
        : ""
    }` + checkInBasicData[checkIn.code as HealthFactorsCodes].name
  );
};
export const getOrderTypesOptions = (checkInsList: BasicCheckIn[]) => {
  return checkInsList.map((checkIn) => ({
    value: checkIn.code,
    label:
      checkIn.type === CHECK_IN_TYPES.bundles
        ? bundlesData[checkIn.code as BundleCodes].label
        : checkInLabel(checkIn),
  }));
};
