import { BasicCheckIn, CHECK_IN_CODES, CHECK_IN_TYPES } from "~/common/utils";
import {
  BundleFormValues,
  ScheduleAppointmentFormValues,
  ScheduleFormValues,
} from "~/providers/components/patientProfile/orderPanel/AddOrderForm";
import { AddOrderFormValues } from "~/providers/modals/AddOrderModal";
import { getOrderByCode } from "~/providers/utils";
import { useAddOrder } from "../useAddOrder";

interface useCheckinSubmitProps {
  patientId: string;
  handleCloseModal: () => void;
}

export const useCheckinSubmit = ({
  handleCloseModal,
  patientId,
}: useCheckinSubmitProps) => {
  const { handleAddBundle, handleAddSchedule, handleAddCheckIn, isPending } =
    useAddOrder({
      handleCloseModal,
      patientId,
    });

  const isBundleData = (
    data: AddOrderFormValues,
    selectedOrder?: BasicCheckIn,
  ): data is BundleFormValues =>
    !!(selectedOrder && selectedOrder.type === CHECK_IN_TYPES.bundles);

  const isScheduleAppointment = (
    data: AddOrderFormValues,
    selectedOrder?: BasicCheckIn,
  ): data is ScheduleAppointmentFormValues =>
    !!(
      selectedOrder &&
      selectedOrder.code === CHECK_IN_CODES.schedule_appointment
    );

  const isScheduleCheckInData = (
    data: AddOrderFormValues,
  ): data is ScheduleFormValues => {
    return (
      !isScheduleAppointment(data) &&
      !isBundleData(data) &&
      !!data.recurringOrder
    );
  };

  const onSubmitCheckin = (
    data: AddOrderFormValues,
    checkInsList: BasicCheckIn[],
  ) => {
    const order = getOrderByCode(data.orderType, checkInsList);

    if (!order?.id)
      throw new Error("There was a problem finding the selected order data.");

    if (isBundleData(data, order)) return handleAddBundle(data, order.id);

    if (isScheduleCheckInData(data)) return handleAddSchedule(data, order?.id);

    handleAddCheckIn(data, order.id);
  };

  return { onSubmitCheckin, isPending };
};
