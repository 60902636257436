import { addMonths, addWeeks, parseISO } from "date-fns";

import { getTodayDate } from "~/common/utils/getTodayDate";
import {
  frequencies,
  FrequencyType,
} from "~/providers/constants/ordersFrequencies";

export const getMinRecurringOrderEndDate = (
  frequency?: FrequencyType,
  dueDateString?: string,
) => {
  if (!frequency || !dueDateString) return getTodayDate();

  const date = parseISO(dueDateString);
  const frequencyNumber = frequencies[frequency].frequency;
  if (frequencies[frequency].interval === "monthly") {
    return addMonths(date, frequencyNumber).toISOString().split("T")[0];
  }
  return addWeeks(date, frequencyNumber).toISOString().split("T")[0];
};
