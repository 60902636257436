import { useEffect } from "react";
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  UseFormWatch,
} from "react-hook-form";

import DatePicker from "~/common/components/ui/DatePicker";
import Select from "~/common/components/ui/select/Select";
import { getTodayDate } from "~/common/utils/getTodayDate";
import { HealthFactorsCodes } from "~/common/utils/types/checkInTypes";
import CheckBox from "~/patients/components/ui/CheckBox";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import {
  ordersFrequencies,
  repeatUntilOptions,
} from "~/providers/constants/ordersFrequencies";
import { getMinRecurringOrderEndDate } from "~/providers/utils/orders";
import { AddOrderFormValues } from "./AddOrderForm";

interface RecurringOrderFormInputsProps {
  resetField: UseFormResetField<AddOrderFormValues>;
  register: UseFormRegister<AddOrderFormValues>;
  errors?: FieldErrors<AddOrderFormValues>;
  control: Control<AddOrderFormValues>;
  selectedOrderCode: HealthFactorsCodes;
  watch: UseFormWatch<AddOrderFormValues>;
  getValues: UseFormGetValues<AddOrderFormValues>;
}

export const RecurringOrderFormInputs = ({
  register,
  errors,
  control,
  watch,
  resetField,
  selectedOrderCode,
}: RecurringOrderFormInputsProps) => {
  const today = getTodayDate();
  const selectedFrequency = watch("frequency");
  const selectedDueDate = watch("dueDate");
  const repeatUntil = watch("repeatUntil");
  const recurringOrder = watch("recurringOrder");
  const endDateDisabled =
    !repeatUntil || repeatUntil === repeatUntilOptions.forEver;
  const minEndDate = getMinRecurringOrderEndDate(
    selectedFrequency,
    selectedDueDate,
  );

  useEffect(() => {
    resetField("endDate");
  }, [repeatUntil, selectedFrequency, resetField]);

  return (
    <div className="flex w-full flex-col items-start gap-3">
      <DatePicker
        label="Order due date"
        min={today}
        id="dueDate"
        {...register("dueDate")}
        error={
          errors && "dueDate" in errors ? errors?.dueDate?.message : undefined
        }
      />
      <CheckBox
        id="recurringOrder"
        className="h-4 w-4 border-2 border-neutral-700 bg-neutral-25"
        labelClassName="text-sm text-gray-70 font-medium"
        {...register("recurringOrder")}
      >
        Repeat this order
      </CheckBox>
      {recurringOrder && (
        <>
          <Select
            containerClassName="gap-2"
            id="frequency"
            control={control}
            placeholder="Select frequency"
            options={ordersFrequencies[selectedOrderCode]}
            error={
              errors && "frequency" in errors
                ? errors?.frequency?.message
                : undefined
            }
            optionsClassName="max-h-32"
          />
          <RadioButtonOption
            variant="simple"
            label="Repeat forever"
            value={repeatUntilOptions.forEver}
            {...register("repeatUntil")}
          />
          <div className="flex flex-col gap-1">
            <div className="flex gap-2">
              <RadioButtonOption
                variant="simple"
                label="Repeat until"
                value={repeatUntilOptions.ends}
                {...register("repeatUntil")}
              />
              <DatePicker
                className="w-full"
                min={minEndDate}
                disabled={endDateDisabled}
                inputClassName="disabled:bg-neutral-25"
                id="endDate"
                {...register("endDate")}
                isError={errors && "endDate" in errors}
              />
            </div>
            {errors && "repeatUntil" in errors && (
              <p className="text-xs text-error">
                {errors.repeatUntil?.message}
              </p>
            )}
            {errors && "endDate" in errors && (
              <p className="text-xs text-error">{errors.endDate?.message}</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};
