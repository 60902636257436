import { useSearchParams } from "react-router-dom";

import {
  PATIENT_FILTER_PARAMS,
  PATIENT_FILTER_PARAMS_VALUES,
} from "~/providers/constants";

export const useHandleMultipleFilters = () => {
  const [params, setParams] = useSearchParams();

  const handleMultipleFilters = (key: string, values: string[]) => {
    params.delete(PATIENT_FILTER_PARAMS.page);
    params.delete(key);
    values.forEach((value) => params.append(key, value));
    setParams(params);
  };

  const listPatientFilterApplied = PATIENT_FILTER_PARAMS_VALUES.some((filter) =>
    params.has(filter),
  );
  return { handleMultipleFilters, listPatientFilterApplied };
};
