import { useParams } from "react-router-dom";

import { Medications } from "~/providers/components/patientProfile/medications/Medications";

export const PatientMedication = () => {
  const { patientId } = useParams();

  if (!patientId) return null;
  return <Medications patientId={patientId} />;
};
