import { useState } from "react";

import { Avatar } from "~/common/assets";
import { ConfirmActionModal, UploadImageModal } from "~/common/components";
import Select from "~/common/components/ui/select/Select";
import { useDeleteFile, useUploadFile } from "~/providers/hooks";
import { useTeamMemberStore } from "~/providers/stores";
import {
  getUpsertProfileImageOptions,
  UPSERT_PROFILE_IMAGE_OPTIONS,
} from "~/providers/utils";
import { CameraCircle } from "./CameraCircle";

interface ProfilePictureHandlerProps {
  src?: string;
}

export const ProfilePictureHandler = ({ src }: ProfilePictureHandlerProps) => {
  const { teamMember, setTeamMember } = useTeamMemberStore();

  const [preview, setPreview] = useState(src);
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleUpsertModal = () => setShowUpsertModal((prev) => !prev);
  const toggleDeleteModal = () => setShowDeleteModal((prev) => !prev);

  const handleOptionSelect = (value: string | number) => {
    value === UPSERT_PROFILE_IMAGE_OPTIONS.edit && toggleUpsertModal();
    value === UPSERT_PROFILE_IMAGE_OPTIONS.delete && toggleDeleteModal();
  };

  const handleSuccessfulUpload = (url: string) => {
    setPreview(url);
    teamMember && setTeamMember({ ...teamMember, profile_picture_path: url });
    toggleUpsertModal();
  };

  const { uploadFileMutation, isPending: pendingUpload } = useUploadFile(
    handleSuccessfulUpload,
  );

  const handleSuccessfulDelete = () => {
    setPreview(undefined);
    teamMember &&
      setTeamMember({ ...teamMember, profile_picture_path: undefined });
    toggleDeleteModal();
  };

  const { deleteFileMutation, isPending: pendingDelete } = useDeleteFile(
    handleSuccessfulDelete,
  );

  return (
    <div className="relative">
      <img
        className="size-20 rounded-full"
        src={preview ?? Avatar}
        alt="profilePicture"
      />
      <div className="absolute bottom-0 right-0">
        <Select
          optionsClassName="w-32"
          id="handleImage"
          options={getUpsertProfileImageOptions(!!preview)}
          onChange={handleOptionSelect}
          buttonContent={CameraCircle}
        />
      </div>
      <UploadImageModal
        key={src}
        preview={preview}
        src={src}
        setPreview={setPreview}
        show={showUpsertModal}
        onClose={toggleUpsertModal}
        handleUpload={uploadFileMutation}
        loadingSubmit={pendingUpload}
        imageClassName="size-40 rounded-full"
      />
      <ConfirmActionModal
        title="Delete Image"
        description="Are you sure you want to delete this image?"
        confirmButtonText="Delete"
        show={showDeleteModal}
        onClose={toggleDeleteModal}
        onConfirm={deleteFileMutation}
        isLoading={pendingDelete}
      />
    </div>
  );
};
