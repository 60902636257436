import { useParams } from "react-router-dom";

import { PatientDetails } from "~/providers/components/patientProfile";
import { useGetPatient } from "~/providers/hooks/queries/useGetPatient";

export const PatientInformation = () => {
  const { patientId } = useParams();

  const { data: patient } = useGetPatient(patientId);

  if (!patient) {
    return null;
  }

  return <PatientDetails patient={patient} />;
};
