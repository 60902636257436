import type { Location } from "react-router-dom";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { Layout, PatientProfileLayout } from "~/providers/layout";
import {
  ForgotPassword,
  Home,
  InternalErrorScreen,
  Login,
  NotFoundScreen,
  PatientActivityFeed,
  PatientInformation,
  PatientOrders,
  ResetPassword,
} from "~/providers/screens";
import { useTeamMemberStore } from "~/providers/stores";
import { PrescriptionRequest } from "../components";
import { PaymentAndCarePlans } from "../components/providerSettings/paymentAndCarePlans";
import { ProviderDetails } from "../components/providerSettings/ProviderDetails";
import { Scheduling } from "../components/providerSettings/Scheduling";
import { SettingsLayout } from "../layout/SettingsLayout";
import { ConsultationRequestsPatientScreen } from "../screens/ConsultationRequestsPatientScreen";
import { ConsultationRequestsScreen } from "../screens/ConsultationRequestsScreen";
import { PatientMedication } from "../screens/patientProfile/PatientMedication";
import { ROUTES } from "./routes";

export const Router = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  const { teamMember } = useTeamMemberStore();

  return (
    <Routes location={previousLocation ?? location}>
      {/* PUBLIC ONLY ROUTES */}
      {!teamMember && (
        <>
          <Route element={<Navigate to={ROUTES.login} replace />} path={"*"} />
          <Route element={<Login />} path={ROUTES.login} />
          <Route element={<ForgotPassword />} path={ROUTES.forgotPassword} />
          <Route element={<ResetPassword />} path={ROUTES.resetPassword} />
        </>
      )}
      {/* PRIVATE ONLY ROUTES */}
      {teamMember && (
        <>
          <Route element={<Navigate to={ROUTES.home} />} path={"*"} />
          <Route path={ROUTES.notFound || "*"} element={<NotFoundScreen />} />
          <Route
            path={ROUTES.internalError}
            element={<InternalErrorScreen />}
          />
          <Route element={<Layout />}>
            <Route element={<Home />} path={ROUTES.home} />
            <Route
              element={<ConsultationRequestsScreen />}
              path={ROUTES.consultationRequests}
            />
            <Route
              element={<PrescriptionRequest />}
              path={ROUTES.prescriptionRequest}
            />

            <Route element={<PatientProfileLayout />}>
              <Route
                element={<ConsultationRequestsPatientScreen />}
                path={ROUTES.consultationRequestsPatient}
              />
              <Route
                element={<PatientActivityFeed />}
                path={ROUTES.activityFeed}
              />
              <Route element={<PatientMedication />} path={ROUTES.medication} />
              <Route element={<PatientInformation />} path={ROUTES.info} />
              <Route element={null} path={ROUTES.ouraRing} />
              <Route element={<PatientOrders />} path={ROUTES.orders} />
            </Route>
            <Route element={<SettingsLayout />}>
              <Route element={<ProviderDetails />} path={ROUTES.account} />
              <Route element={<Scheduling />} path={ROUTES.scheduling} />
              <Route
                element={<PaymentAndCarePlans />}
                path={ROUTES.paymentAndCarePlans}
              />
            </Route>
          </Route>
        </>
      )}
    </Routes>
  );
};
