import { twMerge } from "tailwind-merge";

import { AnswersRenderer } from "~/common/components/answers";
import { DateSquaredLayout } from "~/common/components/layouts/DateSquaredLayout";
import { Drawer } from "~/common/components/ui/Drawer";
import { NoteData } from "~/common/components/ui/NoteData";
import { checkInUtilities } from "~/common/utils/checkIn/checkInUtilities.ts";
import {
  CheckInWithAnswers,
  CompletedAnswerData,
  PatientCheckInQuestion,
} from "~/common/utils/types/checkInTypes";

interface InteractionDrawerProps {
  selectedInteraction?: CheckInWithAnswers;
  answers?: CompletedAnswerData[];
  questions?: PatientCheckInQuestion[];
  handleClose: () => void;
}
export const InteractionDrawer = ({
  selectedInteraction,
  handleClose,
  questions,
  answers,
}: InteractionDrawerProps) => {
  const { formatCheckInCode } = checkInUtilities();
  const hasNote =
    !!selectedInteraction?.note || !!selectedInteraction?.internal_note;
  return (
    <Drawer isOpen={!!selectedInteraction} onClose={handleClose}>
      <div className="flex w-full flex-col gap-3 overflow-auto">
        <div className="inset-0 flex w-full justify-between">
          <div className="flex w-full flex-col items-start justify-center">
            <h2 className="text-xl font-semibold leading-6">
              {formatCheckInCode(selectedInteraction?.checkin.code)}
            </h2>
          </div>
          {selectedInteraction?.due_date && (
            <DateSquaredLayout date={selectedInteraction.due_date} />
          )}
        </div>
        <div
          className={twMerge("flex flex-col gap-4", hasNote && "divide-y-2")}
        >
          <div className="flex flex-col gap-4">
            {selectedInteraction?.note && (
              <NoteData
                seeMoreButton
                title="Message for patient"
                note={selectedInteraction.note}
              />
            )}
            {selectedInteraction?.internal_note && (
              <NoteData
                seeMoreButton
                title="Internal note"
                note={selectedInteraction.internal_note}
              />
            )}
          </div>
          <AnswersRenderer
            answerClassName="p-2"
            className={twMerge(hasNote && "pt-6")}
            answers={answers}
            questions={questions}
          />
        </div>
      </div>
    </Drawer>
  );
};
