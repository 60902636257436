import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { Button, EmptyState } from "~/common/components";
import { MailBoxIcon } from "~/common/components/icons/MailBoxIcon";
import Input from "~/common/components/ui/Input";
import Spinner from "~/common/components/ui/Spinner";
import { BUTTON_VARIANTS } from "~/common/constants/variants";
import { useCreateComment } from "~/providers/hooks/queries/useCreateComment";
import { useGetScriptComments } from "~/providers/hooks/queries/useGetScriptComments";
import {
  SubmitCommentFormValuesType,
  submitScriptCommentSchema,
} from "~/providers/utils";
import { CommentCard } from "./CommentCard";

interface CommentSectionProps {
  id?: string;
}

export const CommentSection = ({ id }: CommentSectionProps) => {
  const { data: comments, isLoading } = useGetScriptComments(id);
  const { createCommentMutation } = useCreateComment(id);

  const {
    handleSubmit,
    reset,
    watch,
    register,
    formState: { errors },
  } = useForm<SubmitCommentFormValuesType>({
    resolver: zodResolver(submitScriptCommentSchema),
  });

  const onSubmit = ({ text }: SubmitCommentFormValuesType) => {
    createCommentMutation(text);
    reset();
  };

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col justify-between gap-1.5 overflow-y-auto border-t bg-primary-20 px-4 py-4 ">
      <p className="gap-1 font-medium">Comments</p>
      <div className="flex h-full flex-col justify-start gap-3 overflow-y-auto">
        {comments?.length ? (
          comments?.map((comment) => (
            <CommentCard key={comment.id} {...comment} />
          ))
        ) : (
          <EmptyState
            className="h-screen-calculated"
            icon={<MailBoxIcon />}
            message="No comments added yet"
          />
        )}
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex items-end gap-2 pt-1"
      >
        <Input
          label="Add comment"
          {...register("text")}
          error={errors.text?.message}
        />
        <Button
          disabled={!watch("text")}
          className="flex w-1/5 items-center disabled:bg-neutral-5"
          type="submit"
          variant={BUTTON_VARIANTS.outline_black}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};
