import { format, parseISO } from "date-fns";

import { InfoCardProps } from "../components/patientProfile/InfoCard";
import { getFormatNumber } from "./GetFormatNumber";
import { getFullName } from "./GetFullName";
import { PatientData } from "./types";

export const getPatientInfoCards: (patient: PatientData) => InfoCardProps[] = (
  patient,
) => {
  const noAvailable = "No available";
  const pronounsValues = patient.pronouns
    ? Object.values(patient.pronouns)
        .join("/")
        .replace(/\b\w/g, (char) => char.toUpperCase())
    : noAvailable;

  const dateOfBirth = patient.birthday
    ? format(parseISO(patient.birthday), "dd/MM/yyyy")
    : noAvailable;

  const emergencyContactFullName = patient.emergency_contacts[0]?.name
    ? getFullName(
        patient.emergency_contacts[0]?.name,
        patient.emergency_contacts[0]?.last_name,
      )
    : noAvailable;

  return [
    {
      title: "Personal Details",
      fieldData: [
        {
          title: "Pronouns",
          content: pronounsValues,
        },
        {
          title: "Date of birth",
          content: dateOfBirth,
        },
        {
          title: "Mobile number",
          content: patient.phone_number
            ? getFormatNumber(patient.phone_number)
            : noAvailable,
        },
        {
          title: "Email",
          content: patient.email ?? noAvailable,
        },
      ],
    },
    {
      title: "Address",
      fieldData: [
        {
          title: "Street address",
          content: patient.addresses[0]?.street ?? noAvailable,
        },
        {
          title: "Apartment",
          content: patient.addresses[0]?.apartment ?? noAvailable,
        },
        {
          title: "City",
          content:
            patient.addresses[0]?.city && patient.addresses[0]?.state
              ? `${patient.addresses[0]?.city}, ${patient.addresses[0]?.state}`
              : noAvailable,
        },
        {
          title: "ZIP code",
          content: patient.addresses[0]?.zipcode ?? noAvailable,
        },
      ],
    },
    {
      title: "Emergency Contact",
      fieldData: [
        {
          title: "Full name",
          content: emergencyContactFullName,
        },
        {
          title: "Relationship",
          content: patient.emergency_contacts[0]?.relationship ?? noAvailable,
        },
        {
          title: "Mobile number",
          content: patient.emergency_contacts[0]?.phone_number
            ? getFormatNumber(patient.emergency_contacts[0]?.phone_number)
            : noAvailable,
        },
        {
          title: "Email",
          content: patient.emergency_contacts[0]?.email ?? noAvailable,
        },
      ],
    },
  ];
};
