export const PATIENT_FILTER_PARAMS = {
  filter: "filter",
  page: "page",
  name: "name",
  consultationStatus: "consultation_status",
  pageSize: "page_size",
  order: "order",
  orderBy: "order_by",
  patientStatus: "patient_status",
} as const;

export const PATIENT_FILTER_PARAMS_VALUES = [
  PATIENT_FILTER_PARAMS.filter,
  PATIENT_FILTER_PARAMS.page,
  PATIENT_FILTER_PARAMS.name,
  PATIENT_FILTER_PARAMS.consultationStatus,
  PATIENT_FILTER_PARAMS.pageSize,
  PATIENT_FILTER_PARAMS.order,
  PATIENT_FILTER_PARAMS.orderBy,
  PATIENT_FILTER_PARAMS.patientStatus,
] as const;
