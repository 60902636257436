export const WeightIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.0853271" width="32" height="32" rx="16" fill="#FBDEEA" />
    <path
      d="M20.8267 13.77C21.1992 13.5087 21.2928 12.9907 20.9872 12.6537C20.4763 12.0904 19.8702 11.6182 19.1948 11.2598C18.2761 10.7722 17.255 10.509 16.215 10.4917C15.1751 10.4744 14.1458 10.7035 13.2114 11.1602C12.5244 11.496 11.903 11.9478 11.3736 12.4937C11.0569 12.8204 11.1333 13.3412 11.4969 13.6147L13.456 15.0885C13.8196 15.362 14.3317 15.2736 14.7085 15.0185C14.8049 14.9531 14.9061 14.8945 15.0114 14.8431C15.3646 14.6704 15.7537 14.5838 16.1469 14.5904C16.54 14.5969 16.926 14.6964 17.2733 14.8807C17.3768 14.9356 17.476 14.9976 17.5702 15.0661C17.9382 15.3335 18.4472 15.439 18.8197 15.1777L20.8267 13.77Z"
      stroke="#EC5D88"
      strokeWidth="2"
    />
    <path
      d="M9.55323 24.5556H22.6177C23.1852 24.5556 23.654 24.1122 23.6855 23.5454L24.5172 8.5732C24.5513 7.96021 24.0634 7.44445 23.4494 7.44445H8.72147C8.10752 7.44445 7.61962 7.96021 7.65367 8.5732L8.48546 23.5454C8.51695 24.1122 8.98568 24.5556 9.55323 24.5556Z"
      stroke="#EC5D88"
      strokeWidth="2"
    />
  </svg>
);
