import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { ChevronIcon } from "~/common/components/icons";
import { Button } from "~/common/components/ui/button";
import { PATIENT_FILTER_PARAMS } from "../constants/patients";
import usePagination from "../hooks/usePagination";

interface PaginationProps {
  totalItems: number;
  currentPage: number;
  totalPages: number;
}
const Pagination = ({
  totalItems,
  currentPage,
  totalPages,
}: PaginationProps) => {
  const [params, setParams] = useSearchParams();

  const paginationRange = usePagination({
    totalItems,
    currentPage,
    totalPages,
  });

  const onPageChange = (number: number) => {
    const newParams = new URLSearchParams(params);
    newParams.set(PATIENT_FILTER_PARAMS.page, number.toString());
    setParams(newParams);
  };
  const onNext = () => {
    const pageNumber = currentPage + 1;
    const newParams = new URLSearchParams(params);
    newParams.set(PATIENT_FILTER_PARAMS.page, pageNumber.toString());
    setParams(newParams);
  };

  const onPrevious = () => {
    const pageNumber = currentPage - 1;
    const newParams = new URLSearchParams(params);
    newParams.set(PATIENT_FILTER_PARAMS.page, pageNumber.toString());
    setParams(newParams);
  };

  return (
    <div className="flex text-sm text-gray-600">
      <Button
        variant="outline-none"
        onClick={onPrevious}
        className="w-10 justify-center p-0 disabled:bg-transparent"
        disabled={currentPage === 1}
      >
        <ChevronIcon
          className={twMerge("h-2.5", currentPage === 1 && "text-gray-400")}
        />
      </Button>

      <div className="flex items-center">
        {paginationRange?.map((pageNumber, index) => {
          if (pageNumber) {
            return (
              <button
                key={index}
                onClick={() => {
                  onPageChange(pageNumber);
                }}
                className={twMerge(
                  "h-10 w-10 rounded-full",
                  currentPage === pageNumber &&
                    "bg-blue-100 font-semibold text-blue-600",
                )}
              >
                {pageNumber}
              </button>
            );
          }

          return (
            <p
              className="flex w-10 justify-center text-lg tracking-wide"
              key={index}
            >
              ...
            </p>
          );
        })}
      </div>

      <Button
        variant="outline-none"
        onClick={onNext}
        className="w-10 justify-center p-0 disabled:bg-transparent"
        disabled={currentPage === totalPages}
      >
        <ChevronIcon
          className={twMerge(
            "h-2.5",
            currentPage === totalPages && "text-gray-400",
          )}
          direction="right"
        />
      </Button>
    </div>
  );
};

export default Pagination;
